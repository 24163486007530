import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const shopLink = {
  cigPoshta:
    'https://cig-poshta.in.ua/product-tag/parliament-soho-super-slims/?utm_source=smsprlsohossl',
  rozetka:
    'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament//?utm_source=smsCDAmarPRL'
};

const HomeScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.packsContainer}>
          <img
            className={styles.headBg}
            src={getImageFromS3('images/25ua-kv3/head-bg.png')}
            alt="head-bg"
          />
          <img
            src={getImageFromS3('images/25ua-kv3/logo.png')}
            alt="headLogo"
            className={styles.headLogo}
          />
          <img
            src={getImageFromS3('images/25ua-kv3/title.png')}
            alt="title"
            className={styles.title}
          />
          <p className={styles.subTitle}>
            Тепер і у супертонкому<br></br> форматі
          </p>
          <img
            src={getImageFromS3('images/25ua-kv3/headPacks.png')}
            alt="headPacks"
            className={styles.headPacks}
          />
        </div>
        <div className={styles.characteristicContainer}>
          <img
            className={styles.contentBg}
            src={getImageFromS3('images/25ua-kv3/content-bg.png')}
            alt="content-bg"
          />
          <div className={styles.filter}>
            <img
              src={getImageFromS3('images/25ua-kv3/filter.png')}
              alt="filter"
            />
          </div>
          <div className={styles.tobacco}>
            <img
              src={getImageFromS3('images/25ua-kv3/tobacco.png')}
              alt="tobacco"
            />
          </div>
          <div className={styles.qrCode}>
            <img
              src={getImageFromS3('images/25ua-kv3/qr-code.png')}
              alt="qr-code"
            />
          </div>
          <img
            src={getImageFromS3('images/25ua-kv3/title.png')}
            alt="title"
            className={styles.bottomTitle}
          />
          <p className={styles.subTitle}>
            Доступний у компактному<br></br> та супертонкому форматах
          </p>
        </div>
        <div className={styles.footerContainer}>
          <img
            src={getImageFromS3('images/25ua-kv3/footer-bg.png')}
            alt="footer-bg"
          />
        </div>
        <div className={styles.buyContainer}>
          <img
            src={getImageFromS3('images/25ua-kv3/buy-bg.png')}
            alt="buy-bg"
          />
          <p className={styles.title}>Де купити?</p>
          <p className={styles.description}>
            Актуальний асортимент можна знайти у роздрібних точках продажу в
            твоєму місті або на офіційних онлайн сервісах, зокрема:
          </p>
          <div className={styles.buttonContainer}>
            <button onClick={() => window.open(shopLink.rozetka, '_blank')}>
              Rozetka
            </button>
            <button onClick={() => window.open(shopLink.cigPoshta, '_blank')}>
              Cig-poshta
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав лист, оскільки залишав запит на отримання інформації про
        продукцію компаній Philip Morris в Україні. <br />
        <a href="/unsubscribe?returnLink=25-sms-prl-sh">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
