import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title:
      'Ти купив будинок і замовив ексклюзивні меблі. Однак тобі повідомили, що доставку доведеться чекати 3 місяці. \n' +
      'Твої дії?',
    answerList: [
      { id: 1.1, title: 'А) вимагатиму привезти все якнайшвидше' },
      { id: 1.2, title: 'Б) скасую замовлення' },
      { id: 1.3, title: 'В) почекаю' },
      {
        id: 1.4,
        title: 'Г) оберу інші меблі, не менш красиві, але зі швидшою доставкою'
      }
    ]
  },
  {
    id: 2,
    title:
      'Ти вдягнув неймовірно красивий аутфіт на звану вечерю. Зайшовши в ресторан, ти помічаєш пляму на своєму одязі. Що робитимеш?',
    answerList: [
      { id: 2.1, title: 'А) поїду перевдягнусь' },
      { id: 2.2, title: 'Б) спробую відмити пляму у вбиральні' },
      { id: 2.3, title: 'В) вибачусь та покину зустріч' },
      { id: 2.4, title: 'Г) зроблю вигляд, що плями немає' }
    ]
  },
  {
    id: 3,
    title:
      'Ти можеш отримати авто своєї мрії на півроку раніше, ніж усі в Україні. Але для цього треба доплатити 10% від вартості. Що скажеш?',
    answerList: [
      { id: 3.1, title: 'А) спробую домовитись про знижку' },
      { id: 3.2, title: 'Б) почекаю й не буду переплачувати' },
      { id: 3.3, title: 'В) погоджусь' },
      { id: 3.4, title: 'Г) пошукаю іншу, не менш ексклюзивну машину' }
    ]
  },
  {
    id: 4,
    title:
      'Ти заселився у найбільш розкішний готель міста. Однак краєвид з вікна — людна вулиця, а не тихий ліс, як ти бронював. Як вчиниш?',
    answerList: [
      { id: 4.1, title: 'А) вимагатиму на рецепції бажаний номер' },
      { id: 4.2, title: 'Б) залишусь у цьому номері' },
      { id: 4.3, title: 'В) поїду в інший готель' },
      {
        id: 4.4,
        title:
          'Г) залишусь в цьому готелі, але якщо не замінять номер — вимагатиму якийсь презент'
      }
    ]
  },
  {
    id: 5,
    title:
      'Твій друг постійно одягає краватку, що була модна пів сторіччя тому. Через це він виглядає недолуго. Чи скажеш ти йому про це?',
    answerList: [
      { id: 5.1, title: 'А) поговорю з ним та поясню ситуацію' },
      { id: 5.2, title: 'Б) попрошу когось йому сказати' },
      { id: 5.3, title: 'В) спробую мʼяко натякнути' },
      { id: 5.4, title: 'Г) не скажу, йому ж подобається ця краватка' }
    ]
  }
];

const resultList = [
  {
    id: 1,
    title: 'Night sky',
    description:
      'Твій герб однозначно включав би зоряне небо, відображаючи чарівність та унікальність. \n\n' +
      'Навіть у темну пору завдяки дисципліні та наполегливості твої результати бездоганні й неповторні.',
    image: getImageFromS3('images/symbol-your-success-challenge/result1.png')
  },
  {
    id: 2,
    title: 'Aqua',
    description:
      'Вода є символом чистоти та постійного розвитку. \n\n' +
      'Властиві тобі креативність та енергія не лише піднімають все на новий рівень, а й постійно тримають у топі. Це і було б основним посланням твого гербу.',
    image: getImageFromS3('images/symbol-your-success-challenge/result2.png')
  },
  {
    id: 3,
    title: 'Platinum',
    description:
      'Благородність та вдосконалення — твої основні правила. \n\n' +
      'Тобі притаманні високі очікування, що призводить до найкращих результатів, якою б не була їх ціна.',
    image: getImageFromS3('images/symbol-your-success-challenge/result3.png')
  },
  {
    id: 4,
    title: 'Silver',
    description:
      'Ти сповідуєш принципи лояльності та свободи. \n\n' +
      'Відсутність обмежень у голові допомагають тобі підкорювати нові вершини з легкістю.',
    image: getImageFromS3('images/symbol-your-success-challenge/result4.png')
  }
];

const SymbolYourSuccessChallenge = ({
  setScreenNumber,
  backgroundImage,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [correctAnswerValue, setCorrectAnswerValue] = useState({});
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [answers, setAnswers] = useState([]);

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [challengeStep]: value }));
    },
    [answers, challengeStep]
  );

  const onFinishClick = () => {
    const answerCounts = {};
    for (const key in answers) {
      const answerNumber = parseInt(
        answers[key].answerId.toString().split('.')[1]
      );
      answerCounts[answerNumber] = (answerCounts[answerNumber] || 0) + 1;
    }

    let mostFrequentAnswer = null;
    let maxCount = 0;
    for (const answer in answerCounts) {
      if (answerCounts[answer] > maxCount) {
        maxCount = answerCounts[answer];
        mostFrequentAnswer = parseInt(answer);
      }
    }

    setCorrectAnswerValue(
      resultList.find((item) => item?.id === mostFrequentAnswer)
    );
    setIsResultScreen(true);
  };

  const handleAfterResult = () => {
    afterResultButtonScreen();
    setIsResultScreen(false);
    setIsWelcomeScreen(true);
    setChallengeStep(0);
    setCorrectAnswerValue({});
    setAnswers([]);
    setSelectQuestion(challengeList[0]);
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Символ твого успіху</p>
          <p className={styles.description}>
            Реалізація та успіх йдуть з тобою пліч-о-пліч.
            <br /> А чи задумувався ти над створенням власного символу?
            <br /> Дізнайся, який герб якнайкраще відображав би твої принципи та
            підходи.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Поїхали</button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <div className={styles.challengeContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.questionContainer}>
            <p className={styles.title}>{selectQuestion?.title}</p>
            <div className={styles.answerList}>
              {selectQuestion &&
                selectQuestion?.answerList?.map((item, index) => (
                  <div
                    key={item?.id}
                    className={classNames(
                      styles.answerItem,
                      item?.id === answers[challengeStep]?.answerId &&
                        styles.selectedAnswerItem
                    )}
                    onClick={() =>
                      onAnswerClick({ answerId: item?.id, answer: item?.title })
                    }
                    style={
                      item?.id === answers[challengeStep]?.answerId
                        ? {
                            backgroundImage: `url("${getImageFromS3(
                              'images/symbol-your-success-challenge/selected-answer-background.png'
                            )}")`
                          }
                        : {
                            backgroundImage: `url("${getImageFromS3(
                              'images/symbol-your-success-challenge/answer-background.png'
                            )}")`
                          }
                    }
                  >
                    <p>{item.title}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                if (challengeStep > 0) {
                  setChallengeStep(challengeStep - 1);
                  setSelectQuestion(challengeList[challengeStep - 1]);
                } else {
                  setIsWelcomeScreen(true);
                  setSelectQuestion(challengeList[0]);
                  setChallengeStep(0);
                  setIsResultScreen(false);
                  setAnswers([]);
                }
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            {answers[challengeStep]?.answerId && (
              <div
                className={styles.rightButton}
                onClick={() => {
                  if (challengeStep + 1 >= challengeList?.length) {
                    onFinishClick();
                    return;
                  }
                  setChallengeStep(challengeStep + 1);
                  setSelectQuestion(challengeList[challengeStep + 1]);
                }}
              >
                <span>Далі</span>
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isResultScreen && !!correctAnswerValue && (
        <div className={styles.resultContainer}>
          <img src={correctAnswerValue?.image} alt="result-image" />
          <p className={styles.title}>{correctAnswerValue?.title}</p>
          <p className={styles.description}>
            {correctAnswerValue?.description}
          </p>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                setChallengeStep(challengeList?.length - 1);
                setIsResultScreen(false);
                setCorrectAnswerValue({});
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div className={styles.rightButton} onClick={handleAfterResult}>
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SymbolYourSuccessChallenge;
