import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './index.module.scss';
import classNames from 'classnames';
import HeadContainer from '../../head-container';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title: 'З яких років запозичений тренд',
    answerList: [
      {
        id: 'left_1',
        title: 'Джинси із заниженою талією',
        order: 1,
        pairId: 'pair_1'
      },
      {
        id: 'left_2',
        title: 'Спортивні костюми',
        order: 2,
        pairId: 'pair_3'
      },
      {
        id: 'left_3',
        title: 'Шкіряні косухи',
        order: 3,
        pairId: 'pair_2'
      }
    ],
    resultList: [
      {
        id: 'right_1',
        title: '2000-ні',
        pairId: 'pair_1',
        order: 1
      },
      {
        id: 'right_2',
        title: '90-ті',
        pairId: 'pair_2',
        order: 2
      },
      {
        id: 'right_3',
        title: '80-ті',
        pairId: 'pair_3',
        order: 3
      }
    ]
  },
  {
    id: 2,
    title: 'З яких років запозичений тренд',
    answerList: [
      {
        id: 'left_4',
        title: 'Квіткові принти, смужки та горошок',
        order: 1,
        pairId: 'pair_4'
      },
      {
        id: 'left_5',
        title: 'Металеві кольори та паєтки',
        order: 2,
        pairId: 'pair_5'
      },
      {
        id: 'left_6',
        title: 'Взуття на платформі або грубій підошві',
        order: 3,
        pairId: 'pair_6'
      }
    ],
    resultList: [
      {
        id: 'right_4',
        title: '90-ті',
        pairId: 'pair_6',
        order: 1
      },
      {
        id: 'right_5',
        title: '60-ті',
        pairId: 'pair_4',
        order: 2
      },
      {
        id: 'right_6',
        title: '80-ті',
        pairId: 'pair_5',
        order: 3
      }
    ]
  },
  {
    id: 3,
    title: 'З яких років запозичений тренд',
    answerList: [
      {
        id: 'left_7',
        title: 'Яскраві тіні та блискучі губи',
        order: 1,
        pairId: 'pair_7'
      },
      {
        id: 'left_8',
        title: 'Оверсайз та багатошаровість',
        order: 2,
        pairId: 'pair_8'
      },
      {
        id: 'left_9',
        title: 'Червоні губи та стрілки',
        order: 3,
        pairId: 'pair_9'
      }
    ],
    resultList: [
      {
        id: 'right_7',
        title: '50-ті',
        pairId: 'pair_9',
        order: 1
      },
      {
        id: 'right_8',
        title: '90-ті',
        pairId: 'pair_8',
        order: 2
      },
      {
        id: 'right_9',
        title: '2000-ні',
        pairId: 'pair_7',
        order: 3
      }
    ]
  }
];

const DndChallenge = ({
  setScreenNumber,
  setNextScreenNumber,
  backgroundImage
}) => {
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);

  const initialAnswers = challengeList.map((q) =>
    [...q.answerList].sort((a, b) => a.order - b.order)
  );
  const initialRightAnswers = challengeList.map((q) =>
    [...q.resultList].sort((a, b) => a.order - b.order)
  );

  const [userAnswers, setUserAnswers] = useState(initialAnswers);
  const [rightAnswers, setRightAnswers] = useState(initialRightAnswers);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentAnswers = userAnswers[currentQuestionIndex];
  const currentRightAnswers = rightAnswers[currentQuestionIndex];
  const [lockedAnswers, setLockedAnswers] = useState(new Set());
  const [incorrectAnswers, setIncorrectAnswers] = useState(new Set());
  const [allCorrect, setAllCorrect] = useState(false);
  const [firstDragPerformed, setFirstDragPerformed] = useState(
    Array(challengeList.length).fill(false)
  );

  useEffect(() => {
    if (
      currentAnswers &&
      currentAnswers.length > 0 &&
      currentRightAnswers &&
      currentRightAnswers.length > 0 &&
      firstDragPerformed[currentQuestionIndex]
    ) {
      checkCorrectPairs();
    }
  }, [
    currentAnswers,
    currentRightAnswers,
    currentQuestionIndex,
    firstDragPerformed
  ]);

  const checkCorrectPairs = () => {
    if (!firstDragPerformed[currentQuestionIndex]) return;

    const newLockedAnswers = new Set();
    const newIncorrectAnswers = new Set();
    let correctPairsCount = 0;

    currentAnswers.forEach((leftItem, leftIndex) => {
      const rightItem = currentRightAnswers[leftIndex];

      if (leftItem.pairId === rightItem.pairId) {
        newLockedAnswers.add(leftItem.id);
        newLockedAnswers.add(rightItem.id);
        correctPairsCount++;
      } else {
        newIncorrectAnswers.add(leftItem.id);
        newIncorrectAnswers.add(rightItem.id);
      }
    });

    setLockedAnswers(newLockedAnswers);
    setIncorrectAnswers(newIncorrectAnswers);
    setAllCorrect(correctPairsCount === currentAnswers.length);

    if (newIncorrectAnswers.size > 0) {
      setTimeout(() => {
        setIncorrectAnswers(new Set());
      }, 1000);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < challengeList.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setLockedAnswers(new Set());
      setIncorrectAnswers(new Set());
      setAllCorrect(false);
    } else {
      setIsResultScreen(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      const newUserAnswers = [...userAnswers];
      const newRightAnswers = [...rightAnswers];

      newUserAnswers[currentQuestionIndex - 1] = [
        ...challengeList[currentQuestionIndex - 1].answerList
      ].sort((a, b) => a.order - b.order);
      newRightAnswers[currentQuestionIndex - 1] = [
        ...challengeList[currentQuestionIndex - 1].resultList
      ].sort((a, b) => a.order - b.order);

      setUserAnswers(newUserAnswers);
      setRightAnswers(newRightAnswers);

      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setLockedAnswers(new Set());
      setIncorrectAnswers(new Set());
      setAllCorrect(false);

      const newFirstDragPerformed = [...firstDragPerformed];
      newFirstDragPerformed[currentQuestionIndex - 1] = false;
      setFirstDragPerformed(newFirstDragPerformed);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const isLeftColumn = source.droppableId === 'left-column';
      const items = isLeftColumn
        ? [...currentAnswers]
        : [...currentRightAnswers];

      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      if (isLeftColumn) {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[currentQuestionIndex] = items;
        setUserAnswers(updatedAnswers);
      } else {
        const updatedRightAnswers = [...rightAnswers];
        updatedRightAnswers[currentQuestionIndex] = items;
        setRightAnswers(updatedRightAnswers);
      }

      if (!firstDragPerformed[currentQuestionIndex]) {
        const newFirstDragPerformed = [...firstDragPerformed];
        newFirstDragPerformed[currentQuestionIndex] = true;
        setFirstDragPerformed(newFirstDragPerformed);
      }

      checkCorrectPairs();
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 0 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                '/images/from-which-years-trend-borrowed-challenge/welcome-background-icon.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>З яких років запозичений тренд</p>
          </div>
          <div className={styles.description}>
            Все нове — це добре забуте старе. А й справді, трендові зараз речі
            вже були модними десятиліття тому.
            <br></br>
            <br></br>
            <b>Спробуй здогадатись, які тренди з якого року до нас прийшли!</b>
          </div>
          <button onClick={() => setIsWelcomeScreen(false)}>Почати</button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === currentQuestionIndex && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <p className={styles.questionTitle}>
            {challengeList[currentQuestionIndex]?.title}
          </p>
          <div className={styles.content}>
            <div className={styles.challengeСontainer}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="left-column">
                  {(provided) => (
                    <div
                      className={styles.answersContainer}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {currentAnswers.map((answer, index) => (
                        <Draggable
                          key={answer.id}
                          draggableId={answer.id.toString()}
                          index={index}
                          isDragDisabled={lockedAnswers.has(answer.id)}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={answer.id}
                              className={classNames(styles.answerItem, {
                                [styles.dragging]: snapshot.isDragging,
                                [styles.correct]: lockedAnswers.has(answer.id),
                                [styles.incorrect]: incorrectAnswers.has(
                                  answer.id
                                )
                              })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={styles.answerItemContainer}>
                                <p>{answer.title}</p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className={styles.challengeСontainer}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="right-column">
                  {(provided) => (
                    <div
                      className={styles.answersContainer}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {currentRightAnswers.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                          isDragDisabled={lockedAnswers.has(item.id)}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={item.id}
                              className={classNames(styles.answerItem, {
                                [styles.dragging]: snapshot.isDragging,
                                [styles.correct]: lockedAnswers.has(item.id),
                                [styles.incorrect]: incorrectAnswers.has(
                                  item.id
                                )
                              })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={styles.answerItemContainer}>
                                <p>{item.title}</p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className={styles.navigationButtons}>
            {currentQuestionIndex > 0 && (
              <button
                className={styles.backButton}
                onClick={handlePreviousQuestion}
              >
                Назад
              </button>
            )}
            {allCorrect && (
              <button
                className={styles.nextButton}
                onClick={handleNextQuestion}
              >
                Далі
              </button>
            )}
          </div>
        </>
      )}
      {isResultScreen && (
        <div className={styles.resultContainer}>
          <img
            src={getImageFromS3(
              '/images/from-which-years-trend-borrowed-challenge/result.png'
            )}
            alt="result"
          />
          <p className={styles.title}>Дякуємо!</p>
          <p className={styles.description}>
            Сподіваємсь, що було цікаво та пізнавально.<br></br>
            Тепер можеш блиснути своїми знаннями перед друзями!
          </p>
          <button onClick={setNextScreenNumber}>Далі</button>
        </div>
      )}
    </div>
  );
};

export default DndChallenge;
