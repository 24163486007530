import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import { challengeComponent } from './content';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { ReactComponent as PlaySongIcon } from '../../../icons/play-song.svg';

const RhythmOfYourEveningChallenge = ({
  setScreenNumber,
  challengeId,
  afterResultButtonScreen
}) => {
  const challengeList = challengeComponent.find(
    (item) => item?.id === challengeId
  );
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(
    challengeList?.questions[0]
  );
  const [correctAnswerValue, setCorrectAnswerValue] = useState(null);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [playListUrl, setPlayListUrl] = useState(null);

  const openPlayList = () => {
    window.open(playListUrl, '_blank');
  };

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [challengeStep]: value }));
    },
    [answers, challengeStep]
  );

  const onFinishClick = () => {
    const answerCounts = {};
    for (const key in answers) {
      const answerNumber = parseInt(
        answers[key].answerId.toString().split('.')[1]
      );
      answerCounts[answerNumber] = (answerCounts[answerNumber] || 0) + 1;
    }

    let mostFrequentAnswer = null;
    let maxCount = 0;
    for (const answer in answerCounts) {
      if (answerCounts[answer] > maxCount) {
        maxCount = answerCounts[answer];
        mostFrequentAnswer = parseInt(answer);
      }
    }

    setCorrectAnswerValue(
      challengeList?.resultList?.find((item) => item?.id === mostFrequentAnswer)
    );
    setIsResultScreen(true);
    setPlayListUrl(
      challengeList?.resultList?.find((item) => item?.id === mostFrequentAnswer)
        ?.playList
    );
  };

  return (
    challengeList && (
      <div
        className={styles.wrapper}
        style={{ backgroundImage: `url(${challengeList.backgroundImage})` }}
      >
        <div style={{ padding: '20px 30px 0' }}>
          <HeadContainer setScreenNumber={setScreenNumber} />
        </div>
        {isWelcomeScreen && !isResultScreen && (
          <div className={styles.welcomeContainer}>
            <p className={styles.title}>{challengeList?.title}</p>
            <p className={styles.description}>{challengeList?.description}</p>
            <p className={styles.subDescription}>
              {challengeList?.subDescription}
            </p>
            <button onClick={() => setIsWelcomeScreen(false)}>
              {challengeList?.startButtonTitle}
            </button>
          </div>
        )}
        {!isWelcomeScreen && !isResultScreen && (
          <div className={styles.challengeContainer}>
            <div className={styles.stepToolbar}>
              {challengeList.questions.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    styles.stepItem,
                    index === challengeStep && styles.activeStepItem
                  )}
                />
              ))}
            </div>
            <div className={styles.questionContainer}>
              <p className={styles.title}>{selectQuestion?.title}</p>
              <div className={styles.imageList}>
                {selectQuestion &&
                  selectQuestion?.answerList?.map((item, index) => (
                    <div
                      key={item?.id}
                      className={classNames(
                        styles.imageItem,
                        item?.id === answers[challengeStep]?.answerId &&
                          styles.selectedImageItem
                      )}
                      onClick={() =>
                        onAnswerClick({
                          answerId: item?.id,
                          answer: item?.title
                        })
                      }
                    >
                      <img src={item?.image} alt={item?.image} />
                      <p>{item?.title}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={styles.leftButton}
                onClick={() => {
                  if (challengeStep > 0) {
                    setChallengeStep(challengeStep - 1);
                    setSelectQuestion(
                      challengeList?.questions[challengeStep - 1]
                    );
                  } else {
                    setIsWelcomeScreen(true);
                    setSelectQuestion(challengeList?.questions[0]);
                    setChallengeStep(0);
                    setIsResultScreen(false);
                    setAnswers([]);
                  }
                }}
              >
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
                <span>Назад</span>
              </div>
              {answers[challengeStep]?.answerId && (
                <div
                  className={styles.rightButton}
                  onClick={() => {
                    if (challengeStep + 1 >= challengeList?.questions?.length) {
                      onFinishClick();
                      return;
                    }
                    setChallengeStep(challengeStep + 1);
                    setSelectQuestion(
                      challengeList?.questions[challengeStep + 1]
                    );
                  }}
                >
                  <span>Далі</span>
                  <img
                    src="/images/luxury-expert-challenge/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {isResultScreen && !isWelcomeScreen && (
          <div className={styles.resultContainer}>
            <p className={styles.headResultTitle}>Результат</p>
            <img src={correctAnswerValue?.image} alt="result-image" />
            <p className={styles.title}>{correctAnswerValue.title}</p>
            <p className={styles.description}>
              {correctAnswerValue.description}
            </p>
            <div className={styles.playButtonContainer}>
              <button onClick={openPlayList}>
                <p>Слухати</p>
                <PlaySongIcon />
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={styles.leftButton}
                onClick={() => {
                  setIsResultScreen(false);
                }}
              >
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
                <span>Назад</span>
              </div>
              {answers[challengeStep]?.answerId && (
                <div
                  className={styles.rightButton}
                  onClick={() => {
                    setChallengeStep(0);
                    setSelectQuestion(challengeList[0]);
                    setCorrectAnswerValue(0);
                    setIsWelcomeScreen(true);
                    setIsResultScreen(false);
                    afterResultButtonScreen();
                  }}
                >
                  <span>Далі</span>
                  <img
                    src="/images/luxury-expert-challenge/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default RhythmOfYourEveningChallenge;
