import { challengeComponent } from './content';
import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';

const handleAnswerIndex = (index) => {
  const letter = () => {
    switch (index) {
      case 0:
        return 'a';
      case 1:
        return 'б';
      case 2:
        return 'в';
      default:
        return 'a';
    }
  };

  return <div className={styles.letterIndex}>{letter()}</div>;
};

const TasteExperimenterChallenge = ({
  setScreenNumber,
  challengeId,
  afterResultButtonScreen
}) => {
  const challengeList = challengeComponent.find(
    (item) => item?.id === challengeId
  );
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(
    challengeList?.questions[0]
  );
  const [correctAnswerValue, setCorrectAnswerValue] = useState(0);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [isAfterResultScreen, setIsAfterResultScreen] = useState(false);
  const [answers, setAnswers] = useState([]);

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [challengeStep]: value }));
    },
    [answers, challengeStep]
  );

  const onFinishClick = () => {
    const answerCounts = {};
    for (const key in answers) {
      const answerNumber = parseInt(
        answers[key].answerId.toString().split('.')[1]
      );
      answerCounts[answerNumber] = (answerCounts[answerNumber] || 0) + 1;
    }

    let mostFrequentAnswer = null;
    let maxCount = 0;
    for (const answer in answerCounts) {
      if (answerCounts[answer] > maxCount) {
        maxCount = answerCounts[answer];
        mostFrequentAnswer = parseInt(answer);
      }
    }

    setCorrectAnswerValue(
      challengeList?.resultList?.find((item) => item?.id === mostFrequentAnswer)
    );
    setIsResultScreen(true);
  };

  return (
    challengeList && (
      <div
        className={styles.wrapper}
        style={{ backgroundImage: `url(${challengeList.backgroundImage})` }}
      >
        <div style={{ padding: '20px 30px 0' }}>
          <HeadContainer setScreenNumber={setScreenNumber} />
        </div>
        {isWelcomeScreen && !isResultScreen && (
          <div className={styles.welcomeContainer}>
            <div className={styles.headContainer}>
              <img
                src={challengeList?.welcomeBackgroundIcon}
                alt="welcome-icon"
              />
              <p className={styles.title}>{challengeList?.title}</p>
            </div>
            <p className={styles.description}>{challengeList?.description}</p>
            <button onClick={() => setIsWelcomeScreen(false)}>
              {challengeList?.startButtonTitle}
            </button>
          </div>
        )}
        {!isWelcomeScreen && !isResultScreen && !isAfterResultScreen && (
          <div className={styles.challengeContainer}>
            <div className={styles.stepToolbar}>
              {challengeList.questions.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    styles.stepItem,
                    index === challengeStep && styles.activeStepItem
                  )}
                />
              ))}
            </div>
            <div className={styles.questionContainer}>
              <p className={styles.title}>{selectQuestion?.title}</p>
              <div className={styles.imageList}>
                {selectQuestion &&
                  selectQuestion?.answerList?.map((item, index) => (
                    <div
                      key={item?.id}
                      className={classNames(
                        styles.answerItem,
                        item?.id === answers[challengeStep]?.answerId &&
                          styles.selectedAnswerItem
                      )}
                      onClick={() =>
                        onAnswerClick({
                          answerId: item?.id,
                          answer: item?.title
                        })
                      }
                    >
                      {handleAnswerIndex(index)}
                      <p>{item?.title}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={styles.leftButton}
                onClick={() => {
                  if (challengeStep > 0) {
                    setChallengeStep(challengeStep - 1);
                    setSelectQuestion(
                      challengeList?.questions[challengeStep - 1]
                    );
                  } else {
                    setIsWelcomeScreen(true);
                    setSelectQuestion(challengeList?.questions[0]);
                    setChallengeStep(0);
                    setIsResultScreen(false);
                    setAnswers([]);
                  }
                }}
              >
                <span>Назад</span>
              </div>
              {answers[challengeStep]?.answerId && (
                <div
                  className={styles.rightButton}
                  onClick={() => {
                    if (challengeStep + 1 >= challengeList?.questions?.length) {
                      onFinishClick();
                      return;
                    }
                    setChallengeStep(challengeStep + 1);
                    setSelectQuestion(
                      challengeList?.questions[challengeStep + 1]
                    );
                  }}
                >
                  <span>Далі</span>
                </div>
              )}
            </div>
          </div>
        )}
        {isResultScreen && !isWelcomeScreen && (
          <div className={styles.resultContainer}>
            <p className={styles.headTitle}>Результат</p>
            <p className={styles.title}>{correctAnswerValue.title}</p>
            <img src={correctAnswerValue?.image} alt="result-image" />
            <p className={styles.description}>
              {correctAnswerValue.description}
            </p>
            <button
              onClick={() => {
                setChallengeStep(0);
                setSelectQuestion(challengeList[0]);
                setCorrectAnswerValue(0);
                setIsResultScreen(false);
                setIsAfterResultScreen(true);
              }}
            >
              <span>Далі</span>
            </button>
          </div>
        )}
        {isAfterResultScreen && !isWelcomeScreen && !isResultScreen && (
          <div className={styles.afterResultContainer}>
            <img
              src={challengeList?.afterResultContent?.image}
              alt="after-result-image"
            />
            <p className={styles.title}>
              {challengeList?.afterResultContent?.title}
            </p>
            <p className={styles.description}>
              {challengeList?.afterResultContent?.description}
            </p>
            <button
              onClick={() => {
                setChallengeStep(0);
                setSelectQuestion(challengeList[0]);
                setCorrectAnswerValue(0);
                setIsResultScreen(false);
                setIsAfterResultScreen(false);
                setIsWelcomeScreen(true);
                afterResultButtonScreen();
              }}
            >
              <span>{challengeList?.afterResultContent?.nextButtonText}</span>
            </button>
          </div>
        )}
      </div>
    )
  );
};

export default TasteExperimenterChallenge;
