import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import { ScreensContext } from '../contexts/screensContext';
import HomeScreen from '../common/lendings/sms-mpm25';

const SmsMpm25 = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('sms-mpm-25');
    sessionStorage.setItem('unsubscription_source', 'Philip_Morris_25');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case 0: {
        return <HomeScreen setScreenNumber={cb} />;
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <ScreensContext.Provider
      value={{
        endSessionType,
        setEndSessionType,
        isDisplayedAll,
        setIsDisplayedAll
      }}
    >
      {getScreen(screenNumber, setScreenNumber)}
    </ScreensContext.Provider>
  );
};

export default SmsMpm25;
