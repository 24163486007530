import React, { useState } from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';
import { EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

const shopLink = {
  cigPoshta:
    'https://cig-poshta.in.ua/product-tag/l-m-lofts/?utm_source=smslmloftscda',
  rozetka:
    'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce;tolshchina139079=kompakt-demi/?utm_source=smsCDAapr25Loft'
};

const packList = [
  {
    id: 'loft-blue',
    url: getImageFromS3('images/sms-lm-loft/loft-blue-pack.png')
  },
  {
    id: 'loft-sea',
    url: getImageFromS3('images/sms-lm-loft/loft-sea-pack.png')
  },
  {
    id: 'loft-purple',
    url: getImageFromS3('images/sms-lm-loft/loft-purple-pack.png')
  }
];

const HomeScreen = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          className={styles.background}
          src={getImageFromS3('images/sms-lm-loft/background.png')}
          alt="head-bg"
        />
        <img
          className={styles.headLogo}
          src={getImageFromS3('images/sms-lm-loft/head-logo.png')}
          alt="head-logo"
        />
        <div className={styles.carousel}>
          <Swiper
            slidesPerView={2}
            spaceBetween={20}
            centeredSlides={true}
            pagination={{
              clickable: true
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
              scale: 0.8 // масштаб неактивних слайдів
            }}
            modules={[EffectCoverflow]}
            className="mySwiper"
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            <div>
              {packList?.map((item, index) => {
                return (
                  <SwiperSlide
                    className={
                      index === activeIndex
                        ? styles.activeSlide
                        : styles.inactiveSlide
                    }
                  >
                    <img
                      src={item?.url}
                      alt={item?.id}
                      className={styles.packItem}
                    />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
        <div className={styles.characteristicContainer}>
          <img
            src={getImageFromS3('images/sms-lm-loft/filter.png')}
            alt="filter"
          />
          <img
            src={getImageFromS3('images/sms-lm-loft/format.png')}
            alt="format"
          />
          <img
            src={getImageFromS3('images/sms-lm-loft/tobacco.png')}
            alt="tobacco"
          />
        </div>
        <div className={styles.buyContainer}>
          <div className={styles.content}>
            <p className={styles.title}>Де купити?</p>
            <p className={styles.description}>
              <span>Актуальний асортимент</span> можна знайти у роздрібних
              точках продажу в твоєму місті або на офіційних онлайн сервісах,
              зокрема:
            </p>
            <div className={styles.buttonContainer}>
              <button onClick={() => window.open(shopLink.cigPoshta, '_blank')}>
                Cig-poshta
              </button>
              <button onClick={() => window.open(shopLink.rozetka, '_blank')}>
                Rozetka
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав лист, оскільки залишав запит на отримання інформації про
        продукцію компаній Philip Morris в Україні. <br />
        <a href="/unsubscribe?returnLink=25-sms-lm-loft">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
