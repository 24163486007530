import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'residents-which-country',
    title: 'Про жителів якої країни йде мова',
    description:
      'Кожен народ має свою культуру\n' +
      'та особливі звичаї.\n' +
      'Перевір, наскільки ти обізнаний\n' +
      'в особливостях різних культур.',
    backgroundImage: getImageFromS3(
      '/images/residents-which-country-challenge/bg.png'
    ),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/residents-which-country-challenge/welcome-background-icon.png'
    ),
    startButtonTitle: 'Гортай!',
    questions: [
      {
        id: 1,
        title:
          'В якій країні не можна залишати чайові, оскільки це сприймуть як грубість',
        answerList: [
          { id: 1.1, title: 'Японія', isCorrect: true },
          { id: 1.2, title: 'Франція', isCorrect: false },
          { id: 1.3, title: 'Ватикан', isCorrect: false }
        ]
      },
      {
        id: 2,
        title:
          'Де прийнято кивати головою, щоб сказати "ні", а похитувати головою, щоб сказати "так"?',
        answerList: [
          { id: 2.1, title: 'Естонія', isCorrect: false },
          { id: 2.2, title: 'Німеччина', isCorrect: false },
          { id: 2.3, title: 'Болгарія', isCorrect: true }
        ]
      },
      {
        id: 3,
        title:
          'В якій країні не прийнято торкатися чи обіймати когось без крайньої потреби, навіть друзів?',
        answerList: [
          { id: 3.1, title: 'Швеція', isCorrect: false },
          { id: 3.2, title: 'Японія', isCorrect: true },
          { id: 3.3, title: 'Велика Британія', isCorrect: false }
        ]
      },
      {
        id: 4,
        title:
          'Де вважається нечемним приходити на зустріч вчасно, а краще трохи запізнитися?',
        answerList: [
          { id: 4.1, title: 'Україна', isCorrect: false },
          { id: 4.2, title: 'Італія', isCorrect: false },
          { id: 4.3, title: 'Аргентина', isCorrect: true }
        ]
      },
      {
        id: 5,
        title:
          'У якій країні дарувати подарунки потрібно двома руками, щоб не образити одержувача?',
        answerList: [
          { id: 5.1, title: 'Південна Корея', isCorrect: true },
          { id: 5.2, title: 'Швейцарія', isCorrect: false },
          { id: 5.3, title: 'Польща', isCorrect: false }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Результат',
        description:
          'Ти тільки починаєш знайомство\n' +
          'зі світом культур!\n' +
          'Продовжуй досліджувати різні країни, і зможеш стати справжнім експертом у цій справі.',
        image: getImageFromS3(
          '/images/residents-which-country-challenge/result1.png'
        )
      },
      {
        id: 2,
        title: 'Результат',
        description:
          'Знавець культур!\n' +
          'Ти вже добре розумієш, наскільки різноманітним є світ,\n' +
          'але ще є чому повчитися.',
        image: getImageFromS3(
          '/images/residents-which-country-challenge/result2.png'
        )
      },
      {
        id: 3,
        title: 'Результат',
        description:
          'Вітаємо! Ти експерт\n' +
          'з міжкультурної комунікації.\n' +
          'Можеш сміливо зустрічатись з представниками будь-якої культури.\n' +
          'Інші можуть брати з тебе приклад!',
        image: getImageFromS3(
          '/images/residents-which-country-challenge/result3.png'
        )
      }
    ],
    afterResultContent: {
      title: 'Сподобалась вікторина?',
      description:
        'Ще більше цікавих викликів шукай в інших тестах. Ми готували їх спеціально для тебе!',
      image: getImageFromS3(
        '/images/residents-which-country-challenge/after-result-image.png'
      ),
      nextButtonText: 'Далі'
    }
  },
  {
    id: 'youth-slang',
    title: 'Наскільки добре ти знаєш молодіжний сленг',
    description:
      'Як ти ставишся до сленгу?\n' +
      'Легко вводиш його у свій лексикон\n' +
      'чи навпаки не любиш запам’ятовувати нові слова?\n' +
      'Пройди тест та дізнайся, наскільки добре ти знаєш сучасні фрази',
    backgroundImage: getImageFromS3('/images/youth-slang-challenge/bg.png'),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/youth-slang-challenge/welcome-background-icon.png'
    ),
    startButtonTitle: 'Почати',
    questions: [
      {
        id: 1,
        title: 'Що означає “рофлити”?',
        answerList: [
          { id: 1.1, title: 'Жартувати', isCorrect: true },
          { id: 1.2, title: 'Плакати', isCorrect: false },
          { id: 1.3, title: 'Злитись', isCorrect: false },
          { id: 1.4, title: 'Працювати', isCorrect: false }
        ]
      },
      {
        id: 2,
        title: 'Що таке "шиперити"?',
        answerList: [
          { id: 2.1, title: 'Готувати їжу', isCorrect: false },
          {
            id: 2.2,
            title: 'Уявляти чи бажати стосунків між персонажами',
            isCorrect: true
          },
          { id: 2.3, title: 'Подорожувати', isCorrect: false },
          { id: 2.4, title: 'Висміювати когось', isCorrect: false }
        ]
      },
      {
        id: 3,
        title: 'Чілити — це…?',
        answerList: [
          { id: 3.1, title: 'Відпочивати', isCorrect: true },
          { id: 3.2, title: 'Працювати', isCorrect: false },
          { id: 3.3, title: 'Навчатися', isCorrect: false },
          { id: 3.4, title: 'Ображатися', isCorrect: false }
        ]
      },
      {
        id: 4,
        title: 'Що означає “зашквар”?',
        answerList: [
          { id: 4.1, title: 'Щось дуже ганебне, принизливе', isCorrect: true },
          { id: 4.2, title: 'Довгоочікувана подія', isCorrect: false },
          { id: 4.3, title: 'Неприємний запах', isCorrect: false },
          { id: 4.4, title: 'Різкий поворот', isCorrect: false }
        ]
      },
      {
        id: 5,
        title: 'А що таке "крінж"?',
        answerList: [
          {
            id: 5.1,
            title: 'Незручність і сором за свої або чужі дії',
            isCorrect: true
          },
          { id: 5.2, title: 'Щось дуже смішне', isCorrect: false },
          { id: 5.3, title: 'Занадто крутий момент', isCorrect: false },
          { id: 5.4, title: 'Втома', isCorrect: false }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Результат',
        description:
          'Гонитва за тим, щоб бути модним — це не про тебе.\n' +
          'Ти прихильник вічної класики\n' +
          'і не піддаєшся швидкоплинним трендам.',
        image: getImageFromS3('/images/youth-slang-challenge/result1.png')
      },
      {
        id: 2,
        title: 'Результат',
        description:
          'Хороший результат!\n' +
          'Ти орієнтуєшся у сленгових словах, але не зловживаєш ними.',
        image: getImageFromS3('/images/youth-slang-challenge/result2.png')
      },
      {
        id: 3,
        title: 'Результат',
        description:
          'Ти справжній експерт сленгу!\n' +
          'Ти легко підхоплюєш тренди\n' +
          'і можеш підкорити будь-яку компанію своїми знаннями.',
        image: getImageFromS3('/images/youth-slang-challenge/result3.png')
      }
    ],
    afterResultContent: {
      title: 'Ти наш краш!',
      description:
        'Цікаво, чому в компаніях ще немає вакансій перекладачів із зумерської?',
      image: getImageFromS3(
        '/images/youth-slang-challenge/after-result-image.png'
      ),
      nextButtonText: 'Далі'
    }
  }
];
