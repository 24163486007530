import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'your-energy-for-day',
    title: 'Твоя\n' + 'Енергія\n' + 'на день',
    description:
      'Ти любиш музику?\n\n' +
      'Пройди тест, а ми порадимо музичний плейлист саме під твій настрій.',
    backgroundImage: getImageFromS3(
      '/images/your-energy-for-day-challenge/bg.png'
    ),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/your-energy-for-day-challenge/welcome-background-icon.png'
    ),
    startButtonTitle: 'Почати',
    questions: [
      {
        id: 1,
        title: 'Яка суперсила тобі\n сьогодні стала б в\n нагоді?',
        answerList: [
          { id: 1.1, title: 'Енергійність' },
          { id: 1.2, title: 'Серйозність' },
          { id: 1.3, title: 'Рішучість' }
        ]
      },
      {
        id: 2,
        title: 'Що буде головним\n для тебе цього дня?',
        answerList: [
          { id: 2.1, title: 'Зустріч з друзями' },
          { id: 2.2, title: 'Робота' },
          { id: 2.3, title: 'Час наодинці' }
        ]
      },
      {
        id: 3,
        title: 'Яка погода ідеально\n пасує до твого\n стану?',
        answerList: [
          { id: 3.1, title: 'Сонячна і тепла' },
          { id: 3.2, title: 'Затишний дощ' },
          { id: 3.3, title: 'Прохолодний вітер' }
        ]
      },
      {
        id: 4,
        title: 'Який колір\n найкраще описує твій\n настрій?',
        answerList: [
          { id: 4.1, title: 'Яскраво-жовтий чи червоний' },
          { id: 4.2, title: 'Ніжно-блакитний чи зелений' },
          { id: 4.3, title: 'Сірий із нотками срібла' }
        ]
      },
      {
        id: 5,
        title: 'Що для тебе означає\n “ідеальний день”?',
        answerList: [
          { id: 5.1, title: 'Сміх і рух' },
          { id: 5.2, title: 'Гармонія і легкість' },
          { id: 5.3, title: 'Вирішення складного завдання' }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Твій плейлист:',
        description:
          'Сьогодні тобі потрібна енергія, щоб зарядити себе і зробити цей день яскравим. Цей плейлист допоможе тобі рухатися вперед.',
        image: getImageFromS3(
          '/images/your-energy-for-day-challenge/result1.png'
        ),
        playList:
          'https://www.youtube.com/playlist?list=PLuFSqtT3HMeBeC_IJnD4XD9ycH6cgbFLI&si=Epq7X5iSUhX4LWdl'
      },
      {
        id: 2,
        title: 'Твій плейлист:',
        description:
          'Тобі потрібен час для відновлення та гармонії. Тримай треки, що налаштують тебе на позитив і розслаблення.',
        image: getImageFromS3(
          '/images/your-energy-for-day-challenge/result2.png'
        ),
        playList:
          'https://www.youtube.com/playlist?list=PLuFSqtT3HMeDUdd-T6Vig3a1eyVvHE7Ah&si=0MfJ4J--lmnzuAVO'
      },
      {
        id: 3,
        title: 'Твій плейлист:',
        description:
          'Ти готовий до викликів і хочеш музики, яка мотивуватиме діяти. Пісні, що підтримають тебе.',
        image: getImageFromS3(
          '/images/your-energy-for-day-challenge/result3.png'
        ),
        playList:
          'https://youtube.com/playlist?list=PLuFSqtT3HMeDWL0e9KgwlmS7rwYeYsZgX&si=UY-iL3yeKahB02Y'
      }
    ],
    afterResultContent: {
      title: 'Сподобався плейлист?',
      description: 'Вмикай відбірні треки та роби кожен день яскравішим!',
      image: getImageFromS3(
        '/images/your-energy-for-day-challenge/after-result-image.png'
      ),
      nextButtonText: 'Далі'
    }
  }
];
