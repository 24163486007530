import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import AllBrandsScreen from '../common/all-brands-screen';
import MenuScreen from '../components/lm-d-s/menu-screen';
import InfoScreen from '../components/lm-d-s/info-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import TrueFalseChallenge from '../common/challenge-component/true-false-challenge';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import {
  twoSpecialCapsuleChallenge,
  twoSpecialCapsuleResultList
} from '../consts/capsules-challenge';
import TwoImageSelectSpecialChallenge from '../components/special-capsules/challenge/two-image-select-special-challenge';
import AuthorityScreen from '../common/authority-screen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const menu = [
  {
    id: 'challenge',
    screenNumber: 5,
    name: 'Челендж березня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'difference',
    screenNumber: 2,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const selectedPack = {
  title: 'L&M Special Purple',
  pack: getImageFromS3('images/packs/lm/special-purple.png'),
  tobaccoIcon: getImageFromS3('images/lm-special/capsule-berries.png'),
  tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
  paperIcon: getImageFromS3('images/lm-special/paper.png'),
  paperText:
    'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
  tobaccoSecond: '',
  filterIcon: getImageFromS3('images/lm-special/filter.png'),
  background: `url("${getImageFromS3('images/lm-special/background.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість новітніх продуктів у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  characteristicListTitle: (
    <div>
      <p>
        Оригінальний Recessed<sup>тм</sup> фільтр
      </p>
      <p>
        Фільтр складається з двох <br /> частин:
      </p>
    </div>
  ),
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const LMSP = ({ setAppNumber }) => {
  const [screenNumber, setScreenNumber] = useState(-2);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('LM-SP');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -2: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/special-capsules.mp4')}
            setScreenNumber={cb}
            nextScreenNumber={1}
            changeScreenImmediately
            skipScreenImmediately
            controls
            buttonStyle="skip-video"
            hasButtonArrow
          />
        );
      }
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-special/background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            newMixtureText={selectedPack?.newMixtureText}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack.filterCharacteristicList}
            infoList={selectedPack.characteristicInfoList}
            tobaccoFirst={selectedPack.tobaccoFirst}
            tobaccoIcon={selectedPack?.tobaccoIcon}
            paperIcon={selectedPack?.paperIcon}
            paperText={selectedPack?.paperText}
            tobaccoSecond={selectedPack.tobaccoSecond}
            packImage={selectedPack.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            characteristicListTitle={selectedPack?.characteristicListTitle}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <TwoImageSelectSpecialChallenge
            setScreenNumber={cb}
            challengeList={twoSpecialCapsuleChallenge}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-special/background.png'
            )}")`}
            resultList={twoSpecialCapsuleResultList}
            setAfterResultScreen={() => setScreenNumber(7)}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            isBigImage
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Ось ми й дізналися солодкий смак твого характеру"
            description="Спробуй саме його, коли захочеться чогось цікавого!"
            image={getImageFromS3(
              'images/special-capsules/two-image-challenge/result.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-special/background.png'
            )}")`}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <TrueFalseChallenge
            setScreenNumber={setScreenNumber}
            type="the-mood-is-new"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-special/background.png'
            )}")`}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LMSP;
