import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'what-do-on-weekend',
    title: 'Чим \n зайнятись \n на вихідних',
    description:
      'Не знаєш чим зайнятись на вихідних? Дай випадку шанс підкинути тобі ідею!',
    backgroundImage: getImageFromS3(
      '/images/what-do-on-weekend-challenge/bg.png'
    ),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/what-do-on-weekend-challenge/welcome-background-icon.png'
    ),
    imageList: [
      {
        id: 0,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/0.png')
      },
      {
        id: 1,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/1.png')
      },
      {
        id: 2,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/2.png')
      },
      {
        id: 3,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/3.png')
      },
      {
        id: 4,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/4.png')
      },
      {
        id: 5,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/5.png')
      },
      {
        id: 6,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/6.png')
      },
      {
        id: 7,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/7.png')
      },
      {
        id: 8,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/8.png')
      },
      {
        id: 9,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/9.png')
      },
      {
        id: 10,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/10.png')
      },
      {
        id: 11,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/11.png')
      },
      {
        id: 12,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/12.png')
      },
      {
        id: 13,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/13.png')
      },
      {
        id: 14,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/14.png')
      },
      {
        id: 15,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/15.png')
      },
      {
        id: 16,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/16.png')
      },
      {
        id: 17,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/17.png')
      },
      {
        id: 18,
        url: getImageFromS3('/images/what-do-on-weekend-challenge/18.png')
      }
    ],
    startButtonTitle: 'Далі!',
    afterResultContent: {
      title: 'Ну як?',
      description: 'Сподіваємось, твої вихідні тепер стали трішки цікавішими.',
      image: getImageFromS3(
        '/images/what-do-on-weekend-challenge/after-result-image.png'
      ),
      nextButtonText: 'Далі '
    }
  }
];
