import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { getImageFromS3 } from '../imageService';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import { ScreensContext } from '../contexts/screensContext';
import HomeScreen from '../components/che-kv2/home-screen';

const CheKV = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('25UA-KV2');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case 0: {
        return (
          <HomeScreen
            setScreenNumber={cb}
            backgroundImage={`url("${getImageFromS3(
              'images/sms-che/bg.png'
            )}")`}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <ScreensContext.Provider
      value={{
        endSessionType,
        setEndSessionType,
        isDisplayedAll,
        setIsDisplayedAll
      }}
    >
      {getScreen(screenNumber, setScreenNumber)}
    </ScreensContext.Provider>
  );
};

export default CheKV;
