import React from 'react';
import { getImageFromS3 } from '../../imageService';

export const brandList = [
  {
    brandId: 'chesterfield',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'Chesterfield',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Chesterfield Original 30',
            pack: getImageFromS3('images/packs/chesterfield/original-30.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 30'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Blue 30',
            pack: getImageFromS3('images/packs/chesterfield/blue-30.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 30'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Original',
            pack: getImageFromS3(
              'images/packs/chesterfield/original-updated.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Blue',
            pack: getImageFromS3('images/packs/chesterfield/blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Chesterfield Purple',
            pack: getImageFromS3('images/packs/chesterfield/purple.png'),
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/ches-prpl/bg.png')}")`,
            isNewMixture: true,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>Оригінальний Recessed™ фільтр</p>
                <p>Фільтр складається з трьох частин:</p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Compact Orange',
            pack: getImageFromS3('images/packs/chesterfield/orange.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/lm-special/filter.png'),
            isDarkButton: false,
            isNewMixture: true,
            newMixtureText: 'Абсолютно нова тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Retuned',
            pack: getImageFromS3('images/packs/chesterfield/retuned.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            isNewMixture: true,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Compact Blue',
            pack: getImageFromS3('images/packs/chesterfield/compact-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/chesterfield-4/filter-icon.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Compact Silver',
            pack: getImageFromS3(
              'images/packs/chesterfield/compact-silver.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/chesterfield-4/filter-icon.png'),
            background: `url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    brandId: 'marlboro',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'Marlboro',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Marlboro Gold Titanium',
            pack: getImageFromS3('images/packs/marlboro/gold-titanium.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/mlb-ttn/bg.png')}")`,
            isMarlboroSpecial: true,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>Firm Filter</p>
                <p>
                  Ущільнений ацетатний фільтр, який краще тримає форму та менше
                  розмокає
                </p>
              </div>
            ),
            filterCharacteristicList: []
          },
          {
            title: 'Marlboro Red XXL 40',
            pack: getImageFromS3('images/packs/marlboro/red-xxl-40.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Gold XXL 40',
            pack: getImageFromS3('images/packs/marlboro/gold-xxl-40.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Red',
            pack: getImageFromS3('images/packs/marlboro/red.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Gold',
            pack: getImageFromS3('images/packs/marlboro/gold.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Silver',
            pack: getImageFromS3('images/packs/marlboro/silver.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Marlboro Touch Red',
            pack: getImageFromS3('images/packs/marlboro/touch-red.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.6',
            filterIcon: getImageFromS3('images/chesterfield-4/filter-icon.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/special-background.png'
            )}")`,
            isMarlboroSpecial: true,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95 mm Slims (90-95мм Cлімc)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Marlboro Touch Gold',
            pack: getImageFromS3('images/packs/marlboro/touch-gold.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/chesterfield-4/filter-icon.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/special-background.png'
            )}")`,
            isMarlboroSpecial: true,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95 mm Slims (90-95мм Cлімc)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Marlboro Fine Touch',
            pack: getImageFromS3('images/packs/marlboro/fine-touch.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Touch',
            pack: getImageFromS3('images/packs/marlboro/touch.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/marlboro/filter.png'),
            background: `url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      }
    ]
  },
  {
    brandId: 'bond',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'BOND STREET',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Bond Street Red Selection',
            pack: getImageFromS3('images/packs/bond/red-selection.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Bond Street Blue Selection',
            pack: getImageFromS3('images/packs/bond/blue-selection.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Bond Street Blue Selection 25',
            pack: getImageFromS3('images/packs/bond/blue-selection-25.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      }
      // {
      //   lineTitle: 'Компактна лінійка',
      //   packsLine: [
      //     {
      //       title: 'Bond Street Premium Blue',
      //       pack: getImageFromS3('images/packs/bond/premium-blue.png'),
      //       tobaccoFirst: 'Вміст смол, мг - 6',
      //       tobaccoSecond: 'Вміст нікотину, мг - 0.5',
      //       filterIcon: getImageFromS3('images/bond/filter.png'),
      //       background: `url("${getImageFromS3(
      //         'images/bond/background-new.png'
      //       )}")`,
      //       characteristicInfoList: [
      //         {
      //           title: 'Формат ',
      //           description: '- King Size Slim (Кінг сайз слім)'
      //         },
      //         {
      //           title: 'Кількість сигарет у пачці ',
      //           description: '– 20'
      //         },
      //         {
      //           title: 'Кількість пачок у блоці ',
      //           description: '– 10'
      //         }
      //       ],
      //       filterCharacteristicList: [
      //         {
      //           description: (
      //             <span>
      //               <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
      //               який затримує тверді частинки сигаретного диму.
      //             </span>
      //           ),
      //           bordered: false
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Bond Street Premium Silver',
      //       pack: getImageFromS3('images/packs/bond/premium-silver.png'),
      //       tobaccoFirst: 'Вміст смол, мг - 4',
      //       tobaccoSecond: 'Вміст нікотину, мг - 0.3',
      //       filterIcon: getImageFromS3('images/bond/filter.png'),
      //       background: `url("${getImageFromS3(
      //         'images/bond/background-new.png'
      //       )}")`,
      //       characteristicInfoList: [
      //         {
      //           title: 'Формат ',
      //           description: '- King Size Slim (Кінг сайз слім)'
      //         },
      //         {
      //           title: 'Кількість сигарет у пачці ',
      //           description: '– 20'
      //         },
      //         {
      //           title: 'Кількість пачок у блоці ',
      //           description: '– 10'
      //         }
      //       ],
      //       filterCharacteristicList: [
      //         {
      //           description: (
      //             <span>
      //               <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
      //               який затримує тверді частинки сигаретного диму.
      //             </span>
      //           ),
      //           bordered: false
      //         }
      //       ]
      //     }
      //     // {
      //     //   title: 'Bond Street Premium Mix',
      //     //   pack: getImageFromS3('/images/packs/bond/premium-mix.png'),
      //     //   tobaccoFirst: 'Вміст смол, мг - 5',
      //     //   tobaccoSecond: 'Вміст нікотину, мг - 0.4',
      //     //   filterIcon: getImageFromS3('/images/bond/filter.png'),
      //     //   characteristicInfoList: [
      //     //     {
      //     //       title: 'Формат ',
      //     //       description: '- King Size Slim (Кінг сайз слім)'
      //     //     },
      //     //     {
      //     //       title: 'Кількість сигарет у пачці ',
      //     //       description: '– 20'
      //     //     },
      //     //     {
      //     //       title: 'Кількість пачок у блоці ',
      //     //       description: '– 10'
      //     //     }
      //     //   ],
      //     //   filterCharacteristicList: [
      //     //     {
      //     //       description: (
      //     //         <span>
      //     //           <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
      //     //           який затримує тверді частинки сигаретного диму. Містить
      //     //           капсулу зі смаком лісових ягід.
      //     //         </span>
      //     //       ),
      //     //       bordered: false
      //     //     }
      //     //   ]
      //     // }
      //   ]
      // }
    ]
  },
  {
    brandId: 'lm',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'L&M',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'L&M Red Label XXL 40',
            pack: getImageFromS3('images/packs/lm/red-label-xxl-40.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Blue Label XXL 40',
            pack: getImageFromS3('images/packs/lm/blue-label-xxl-40.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Red Label',
            pack: getImageFromS3('images/packs/lm/red-label.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Blue Label',
            pack: getImageFromS3('images/packs/lm/blue-label.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/bond/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'L&M Loft Splash',
            pack: getImageFromS3('images/packs/lm/loft-splash.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/lm-special/filter.png'),
            isDarkButton: false,
            isNewMixture: true,
            newMixtureText: 'Абсолютно нова тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Purple',
            pack: getImageFromS3('images/packs/lm/loft-purple.png'),
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/lm-special/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            isNewMixture: true,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Green',
            pack: getImageFromS3('images/packs/lm/loft-green.png'),
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/lm-special/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            isNewMixture: true,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Blue',
            pack: getImageFromS3('images/packs/lm/loft-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Sea Blue',
            pack: getImageFromS3('images/packs/lm/loft-sea-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
          // {
          //   title: 'L&M Loft Double Splash',
          //   pack: getImageFromS3('/images/packs/lm/loft-double-splash.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 6',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.5',
          //   filterIcon: getImageFromS3('/images/lm-double-splash/filter-icon.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр з високоякісної целюлози, який
          //           затримує тверді частинки сигаретного диму. Містить капсулу
          //           зі смаком зелених соковитих фруктів та капсулу зі смаком
          //           літнього нектару.
          //         </span>
          //       ),
          //       bordered: true
          //     }
          //   ]
          // },
          // {
          //   title: 'L&M Loft Mix',
          //   pack: getImageFromS3('/images/packs/lm/loft-mix.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 5',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.4',
          //   filterIcon: getImageFromS3('/images/lm-loft-mix/filter.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           <b>Класичний ацетатний фільтр</b>
          //           <br /> з високоякісної целюлози, який затримує тверді
          //           частинки сигаретного диму. Містить капсулу зі смаком лісових
          //           ягід.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      }
      // {
      //   lineTitle: 'НОВІТНІ ТЮТЮНОВІ ВИРОБИ з капсулою',
      //   packsLine: [
      //     {
      //       title: 'L&M Special Purple',
      //       pack: getImageFromS3('images/packs/lm/special-purple.png'),
      //       tobaccoIcon: getImageFromS3(
      //         'images/lm-special/capsule-berries.png'
      //       ),
      //       tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
      //       paperIcon: getImageFromS3('images/lm-special/paper.png'),
      //       paperText:
      //         'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
      //       tobaccoSecond: '',
      //       filterIcon: getImageFromS3('images/lm-special/filter.png'),
      //       background: `url("${getImageFromS3(
      //         'images/lm-special/background.png'
      //       )}")`,
      //       characteristicInfoList: [
      //         {
      //           title: 'Формат ',
      //           description: '- King Size Slim (Кінг сайз слім)'
      //         },
      //         {
      //           title: 'Кількість новітніх продуктів у пачці ',
      //           description: '– 20'
      //         },
      //         {
      //           title: 'Кількість пачок у блоці ',
      //           description: '– 10'
      //         }
      //       ],
      //       characteristicListTitle: (
      //         <div>
      //           <p>
      //             Оригінальний Recessed<sup>тм</sup> фільтр
      //           </p>
      //           <p>
      //             Фільтр складається з двох <br /> частин:
      //           </p>
      //         </div>
      //       ),
      //       filterCharacteristicList: [
      //         {
      //           description: <span>Ацетатна частина</span>,
      //           bordered: true
      //         },
      //         {
      //           description: (
      //             <span>
      //               Ущільнена повітряна камера з ацетатною стінкою, що робить
      //               смак м'яким і більш збалансованим
      //             </span>
      //           ),
      //           bordered: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'L&M Special Red',
      //       pack: getImageFromS3('images/packs/lm/special-red.png'),
      //       tobaccoIcon: getImageFromS3('images/lm-special/capsule-fruit.png'),
      //       tobaccoFirst: 'Містить капсулу з присмаком кавуна',
      //       tobaccoSecond: '',
      //       paperIcon: getImageFromS3('images/lm-special/paper.png'),
      //       paperText:
      //         'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
      //       filterIcon: getImageFromS3('images/lm-special/filter.png'),
      //       background: `url("${getImageFromS3(
      //         'images/lm-special/background.png'
      //       )}")`,
      //       characteristicListTitle: (
      //         <div>
      //           <p>
      //             Оригінальний Recessed<sup>тм</sup> фільтр
      //           </p>
      //           <p>
      //             Фільтр складається з двох <br /> частин:
      //           </p>
      //         </div>
      //       ),
      //       characteristicInfoList: [
      //         {
      //           title: 'Формат ',
      //           description: '- King Size Slim (Кінг сайз слім)'
      //         },
      //         {
      //           title: 'Кількість новітніх продуктів у пачці ',
      //           description: '– 20'
      //         },
      //         {
      //           title: 'Кількість пачок у блоці ',
      //           description: '– 10'
      //         }
      //       ],
      //       filterCharacteristicList: [
      //         {
      //           description: <span>Ацетатна частина</span>,
      //           bordered: true
      //         },
      //         {
      //           description: (
      //             <span>
      //               Ущільнена повітряна камера з ацетатною стінкою, що робить
      //               смак м'яким і більш збалансованим
      //             </span>
      //           ),
      //           bordered: true
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  {
    brandId: 'philip-morris',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'PHILIP MORRIS',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Philip Morris Red 25 Edition',
            pack: getImageFromS3(
              'images/packs/philip-morris/red-25-edition.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/pmn/filter.png'),
            background: `url("${getImageFromS3(
              'images/philip-morris/bg.png'
            )}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з<br /> високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Philip Morris Blue 25 Edition',
            pack: getImageFromS3(
              'images/packs/philip-morris/blue-25-edition.png'
            ),
            background: `url("${getImageFromS3(
              'images/philip-morris/bg.png'
            )}")`,
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/pmn/filter.png'),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з<br /> високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
          // {
          //   title: 'Philip Morris Red',
          //   pack: getImageFromS3('/images/packs/philip-morris/red.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 9',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.7',
          //   filterIcon: getImageFromS3('/images/pmn/filter.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim ("Кінг сайз")'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр з<br /> високоякісної целюлози,
          //           який затримує тверді частинки сигаретного диму.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          // {
          //   title: 'Philip Morris Novel Blue',
          //   pack: getImageFromS3('images/packs/philip-morris/novel-blue.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 6',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.5',
          //   filterIcon: getImageFromS3('images/pmn/filter.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр, який краще тримає форму та менше
          //           розмокає
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // },
          // {
          //   title: 'Philip Morris Novel Silver',
          //   pack: getImageFromS3('images/packs/philip-morris/novel-silver.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 4',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.3',
          //   filterIcon: getImageFromS3('images/pmn/filter.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр, який краще тримає форму та менше
          //           розмокає
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // },
          {
            title: 'Philip Morris Novel Purple',
            pack: getImageFromS3('images/packs/philip-morris/novel-purple.png'),
            background: `url("${getImageFromS3(
              'images/lm-special/new-background.png'
            )}")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            isNewMixture: false,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
          // {
          //   title: 'Philip Morris Novel Mix Summer',
          //   pack: getImageFromS3('/images/packs/philip-morris/novel-mix-summer.png'),
          //   tobaccoFirst: 'Вміст смол, мг - 6',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.5',
          //   filterIcon: getImageFromS3('/images/pmn/pmnms/filter.png'),
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           <b>Класичний ацетатний фільтр</b>
          //           <br /> з високоякісної целюлози, який затримує тверді
          //           частинки сигаретного диму. Містить капсулу зі смаком літніх
          //           соковитих фруктів.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      }
    ]
  },
  {
    brandId: 'parliament',
    background: `url("${getImageFromS3(
      'images/lm-double-splash/violet-background.png'
    )}")`,
    brandTitle: 'Parliament',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Parliament Carat Purple',
            pack: getImageFromS3('images/packs/parliament/carat-purple.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95мм Slims ("90-95мм слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Night Blue',
            pack: getImageFromS3('images/packs/parliament/night-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Aqua Blue',
            pack: getImageFromS3('images/packs/parliament/aqua-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Silver Blue',
            pack: getImageFromS3('images/packs/parliament/silver-blue.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Platinum',
            pack: getImageFromS3('images/packs/parliament/platinum.png'),
            tobaccoFirst: 'Вміст смол, мг - 1',
            tobaccoSecond: 'Вміст нікотину, мг - 0.1',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Лінійка Суперслімс',
        packsLine: [
          {
            title: 'Parliament Aqua Super Slims',
            pack: getImageFromS3(
              'images/packs/parliament/aqua-super-slims.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Silver Super Slims',
            pack: getImageFromS3(
              'images/packs/parliament/silver-super-slims.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 3',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Лінійка Soho NYC',
        packsLine: [
          {
            title: 'Parliament Soho Compact Blue',
            pack: getImageFromS3(
              'images/packs/parliament/soho-compact-blue.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Soho Compact Silver',
            pack: getImageFromS3(
              'images/packs/parliament/soho-compact-silver.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Soho Vibe',
            pack: getImageFromS3('images/packs/parliament/soho-vibe.png'),
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Super Slim (Кінг сайз супер слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Лінійка Soho NYC\n Суперслімс',
        packsLine: [
          {
            title: 'Parliament Soho Aqua Super Slims',
            pack: getImageFromS3(
              'images/packs/parliament/soho-aqua-super-slims.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Soho Silver Super Slims',
            pack: getImageFromS3(
              'images/packs/parliament/soho-silver-super-slims.png'
            ),
            tobaccoFirst: 'Вміст смол, мг - 3',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: getImageFromS3('images/parliament/filter.png'),
            background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      }
    ]
  }
];
