import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const PrlSsModal = ({
  isOpen,
  onClose,
  gifUrl,
  title,
  subTitle,
  description
}) => {
  return (
    isOpen && (
      <div className={styles.wrapper}>
        <div className={styles.modalWrapper}>
          <img
            src={getImageFromS3('images/common/modals/prl-ss-modal/close.png')}
            alt="close"
            className={styles.closeIcon}
            onClick={onClose}
          />
          <p className={styles.title}>{title}</p>
          <p className={styles.subTitle}>{subTitle}</p>
          <img
            src={getImageFromS3(gifUrl)}
            alt="gif-image"
            className={styles.gifImage}
          />
          <div className={styles.arrowContainer}>
            <img
              src={getImageFromS3(
                'images/common/modals/prl-ss-modal/arrow.png'
              )}
              alt="left-arrow"
            />
            <img
              src={getImageFromS3(
                'images/common/modals/prl-ss-modal/arrow.png'
              )}
              alt="right-arrow"
            />
          </div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    )
  );
};

export default PrlSsModal;
