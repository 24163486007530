import React, { useMemo, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import { getImageFromS3 } from '../../../imageService';
import classNames from 'classnames';

const streetList = [
  {
    id: 1,
    title: 'Принс Стріт',
    description:
      'Простягається від Бродвею до Західного Сохо, є однією з найжвавіших і найатмосферніших у районі. Принс-стріт славиться модними бутиками та концептуальними магазинами. Ідеальне місце, щоб насолодитися модним Сохо.',
    image: getImageFromS3('images/soho-tour-challenge/prince.png'),
    top: 30,
    left: 92
  },
  {
    id: 2,
    title: 'Грін Стріт',
    description:
      'Вулиця, що ідеально поєднує історію та сучасність. Тут зосереджені одні з найкрасивіших чавунних будівель Нью-Йорка. Саме вони створюють впізнаваний ландшафт Сохо. Ця вулиця для любителів артгалерей і бутиків.',
    image: getImageFromS3('images/soho-tour-challenge/green.png'),
    top: 120,
    left: 157
  },
  {
    id: 3,
    title: 'Вустер Стріт',
    description:
      'Затишна і творча вулиця, що приваблює шукачів натхнення. Тут є галереї сучасного мистецтва, дизайнерські шоуруми та місця з різноманітними культурними заходами.',
    image: getImageFromS3('images/soho-tour-challenge/wooster.png'),
    top: 185,
    left: 60
  },
  {
    id: 4,
    title: 'Спрінг Стріт',
    description:
      "Ця вулиця — мікс моди, їжі та мистецтва. Від магазинів із вінтажними знахідками до найкращих кав'ярень Сохо. Ідеальне місце для тих, хто хоче прогулятися без поспіху й знайти щось цікаве на кожному кроці.",
    image: getImageFromS3('images/soho-tour-challenge/spring.png'),
    top: 195,
    left: 185
  },
  {
    id: 5,
    title: 'Мерсер Стріт',
    description:
      'Вулиця для тих, хто цінує у стилі індивідуальність. Саме тут розташовані шоуруми сучасних дизайнерів. Атмосфера цієї вулиці — камерна, з акцентом на унікальність і стиль. Це приваблює як місцевих мешканців, так і поціновувачів моди з усього світу.',
    image: getImageFromS3('images/soho-tour-challenge/merser.png'),
    top: 275,
    left: 110
  }
];

const StreetItem = ({ top, left, title, onClick, openStreetList, street }) => {
  const isOpened = openStreetList.find((item) => item?.id === street?.id);

  return (
    <div
      className={styles.streetItem}
      style={{ top, left }}
      onClick={onClick}
      id={street?.id}
    >
      <div
        className={classNames(
          styles.streetItemWrapper,
          !!isOpened && styles.openedStreetItem
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="41"
          viewBox="0 0 33 41"
          fill="none"
        >
          <path
            d="M33 16.5577C33 25.7023 24.5536 32.9183 16.5 41C8.44643 32.9183 0 25.7023 0 16.5577C0 7.41313 7.3873 0 16.5 0C25.6127 0 33 7.41313 33 16.5577Z"
            fill="#163A6A"
          />
          <circle cx="16.5" cy="16.5" r="8.5" fill="#B3DEFF" />
        </svg>
      </div>
      <p className={styles.streetItemTitle}>{title}</p>
    </div>
  );
};

const SohoTourChallenge = ({ setScreenNumber, afterResultButtonScreen }) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [openStreetList, setOpenStreetList] = useState([]);
  const isViewedAll = useMemo(
    () =>
      openStreetList.filter(
        (item, index, self) =>
          index === self.findIndex((el) => el.id === item.id)
      )?.length === 5,
    [openStreetList]
  );

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${getImageFromS3(
          '/images/soho-tour-challenge/bg.png'
        )})`
      }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !selectedStreet && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                '/images/soho-tour-challenge/welcome-background-icon.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>Екскурсія по Сохо</p>
          </div>
          <p className={styles.description}>
            Сохо — це осередок цікавих місць та гучних подій. Тицяй на точки на
            карті, щоб дізнатись більше про видатні локації цього району.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Поїхали</button>
        </div>
      )}
      {!isWelcomeScreen && !selectedStreet && (
        <div className={styles.contentContainer}>
          <div className={styles.stepToolbar}>
            {streetList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.mapContainer}>
            <img
              className={styles.mapImage}
              src={getImageFromS3('images/soho-tour-challenge/map.png')}
              alt="map-background"
            />
            {streetList.map((item, index) => (
              <StreetItem
                key={index.toString()}
                top={item?.top}
                left={item?.left}
                title={item?.title}
                onClick={() => {
                  setSelectedStreet(item);
                  setOpenStreetList([...openStreetList, item]);
                  setChallengeStep(challengeStep + 1);
                }}
                openStreetList={openStreetList}
                street={item}
              />
            ))}
          </div>
          <div className={styles.buttonContainer}>
            {isViewedAll ? (
              <button
                onClick={() => {
                  afterResultButtonScreen();
                  setIsWelcomeScreen(true);
                  setSelectedStreet(null);
                  setOpenStreetList([]);
                }}
                className={styles.afterResultButton}
              >
                Далі
              </button>
            ) : (
              <button
                onClick={() => {
                  setIsWelcomeScreen(true);
                  setSelectedStreet(null);
                  setOpenStreetList([]);
                }}
              >
                На початок
              </button>
            )}
          </div>
        </div>
      )}
      {selectedStreet && !isWelcomeScreen && (
        <div className={styles.selectedItemWrapper}>
          <div className={styles.selectedItem}>
            <img src={selectedStreet?.image} alt="image-preview" />
            <p className={styles.title}>{selectedStreet?.title}</p>
            <p className={styles.description}>{selectedStreet?.description}</p>
          </div>
          <button
            onClick={() => {
              setSelectedStreet(null);
            }}
          >
            Назад
          </button>
        </div>
      )}
    </div>
  );
};

export default SohoTourChallenge;
