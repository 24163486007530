import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'find-extra',
    title: 'Знайди зайве',
    description:
      'Не сумніваємося, що ти багато знаєш і постійно вдосконалюєшся. А як щодо уміння знаходити зайве?',
    backgroundImage: getImageFromS3('/images/find-extra-challenge/bg.png'),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/find-extra-challenge/welcome-background-icon.png'
    ),
    startButtonTitle: 'Пройти',
    questions: [
      {
        id: 1,
        title:
          'Підказка: на трьох фото\n зображені українські\n міста-мільйонники',
        answerList: [
          {
            id: 1.1,
            title: 'Київ',
            image: getImageFromS3('/images/find-extra-challenge/1.1.png'),
            isCorrect: false
          },
          {
            id: 1.2,
            title: 'Тернопіль',
            image: getImageFromS3('/images/find-extra-challenge/1.2.png'),
            isCorrect: true
          },
          {
            id: 1.3,
            title: 'Одеса',
            image: getImageFromS3('/images/find-extra-challenge/1.3.png'),
            isCorrect: false
          },
          {
            id: 1.4,
            title: 'Харків',
            image: getImageFromS3('/images/find-extra-challenge/1.4.png'),
            isCorrect: false
          }
        ]
      },
      {
        id: 2,
        title: 'Підказка: один з цих\n  напоїв не є кавою',
        answerList: [
          {
            id: 2.1,
            title: 'Лате',
            image: getImageFromS3('/images/find-extra-challenge/2.1.png'),
            isCorrect: false
          },
          {
            id: 2.2,
            title: 'Еспресо',
            image: getImageFromS3('/images/find-extra-challenge/2.2.png'),
            isCorrect: false
          },
          {
            id: 2.3,
            title: 'Лимонад',
            image: getImageFromS3('/images/find-extra-challenge/2.3.png'),
            isCorrect: true
          },
          {
            id: 2.4,
            title: 'Американо',
            image: getImageFromS3('/images/find-extra-challenge/2.4.png'),
            isCorrect: false
          }
        ]
      },
      {
        id: 3,
        title: "Підказка: Один із жанрів\n обов'язково містить\n музику",
        answerList: [
          {
            id: 3.1,
            title: 'Комедія',
            image: getImageFromS3('/images/find-extra-challenge/3.1.png'),
            isCorrect: false
          },
          {
            id: 3.2,
            title: 'Мюзикл',
            image: getImageFromS3('/images/find-extra-challenge/3.2.png'),
            isCorrect: true
          },
          {
            id: 3.3,
            title: 'Драма',
            image: getImageFromS3('/images/find-extra-challenge/3.3.png'),
            isCorrect: false
          },
          {
            id: 3.4,
            title: 'Трилер',
            image: getImageFromS3('/images/find-extra-challenge/3.4.png'),
            isCorrect: false
          }
        ]
      },
      {
        id: 4,
        title:
          'Підказка: одна з цих\n страв не є традиційною\n для української кухні',
        answerList: [
          {
            id: 4.1,
            title: 'Вареники',
            image: getImageFromS3('/images/find-extra-challenge/4.1.png'),
            isCorrect: false
          },
          {
            id: 4.2,
            title: 'Борщ',
            image: getImageFromS3('/images/find-extra-challenge/4.2.png'),
            isCorrect: false
          },
          {
            id: 4.3,
            title: 'Котлета по-київськи',
            image: getImageFromS3('/images/find-extra-challenge/4.3.png'),
            isCorrect: false
          },
          {
            id: 4.4,
            title: 'Піца',
            image: getImageFromS3('/images/find-extra-challenge/4.4.png'),
            isCorrect: true
          }
        ]
      },
      {
        id: 5,
        title: 'Підказка: Один із них —\n не континент',
        answerList: [
          {
            id: 5.1,
            title: 'Африка',
            image: getImageFromS3('/images/find-extra-challenge/5.1.png'),
            isCorrect: false
          },
          {
            id: 5.2,
            title: 'Антарктида',
            image: getImageFromS3('/images/find-extra-challenge/5.2.png'),
            isCorrect: false
          },
          {
            id: 5.3,
            title: 'Китай',
            image: getImageFromS3('/images/find-extra-challenge/5.3.png'),
            isCorrect: true
          },
          {
            id: 5.4,
            title: 'Австралія',
            image: getImageFromS3('/images/find-extra-challenge/5.4.png'),
            isCorrect: false
          }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Непогано!',
        description:
          'Але завжди можна покращувати свій результат.\n' +
          'Продовжуй тренувати допитливість та розвивати свої здібності.'
      },
      {
        id: 2,
        title: 'Дуже добре!',
        description:
          'Ти добре впорався із завданням.\n' +
          'Твої знання та кмітливість допомагають тобі у будь-яких челенджах.'
      },
      {
        id: 3,
        title: 'Браво!',
        description:
          'Ти правильно відповів на усі питання.\n' +
          'Схоже, що ти знаєшся на оптимізації та завжди з легкістю знаходиш рішення для найскладніших завдань.'
      }
    ],
    afterResultContent: {
      title: 'Задоволений грою?',
      description:
        'Вміння помічати зайве є дуже важливим. Не забувай тренувати його і використовувати в повсякденному житті.',
      image: getImageFromS3(
        '/images/find-extra-challenge/after-result-image.png'
      ),
      nextButtonText: 'Ще раз!'
    }
  }
];
