import React, { useState } from 'react';
import styles from './index.module.scss';
import apiService from '../../../utils/apiService';
import classNames from 'classnames';

const EmailApproveScreen = ({ backgroundImage, handleNextButtonClick }) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    const unsubscriptionSource = sessionStorage.getItem(
      'unsubscription_email_source'
    );
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      try {
        await apiService.post('notifications/email/unsubscribe', {
          data: {
            value: email,
            source: unsubscriptionSource || 'Parliament_Limited'
          }
        });
        handleNextButtonClick();
      } catch (e) {
        setError('Щось пішло не так, спробуйте ще раз!');
      }
    } else {
      setError('Невірний формат номера');
    }
  };

  return (
    <div className={styles.container} style={{ backgroundImage }}>
      <p className={styles.title}>Дякуємо, що був(-ла) з нами!</p>
      <div className={styles.inputContainer}>
        <p className={styles.subTitle}>Введи свій email, щоб відписатися:</p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={() => setError('')}
          placeholder="email@gmail.com"
          required
          className={classNames(error && styles.errorInput)}
        />
        {error && <p className={styles.error}>Невірний формат email</p>}
      </div>
      <button onClick={handleSubmit}>Далі</button>
    </div>
  );
};

export default EmailApproveScreen;
