import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'your-ideal-motorcycle',
    title: 'Твій ідеальний мотоцикл',
    description:
      'У тебе багато енергії та потенціалу для найсміливіших мрій та ідей.\n' +
      'А як щодо двоколісних пригод?\n' +
      'Проходь тест та дізнайся, який твій ідеальний байк.',
    backgroundImage: getImageFromS3(
      '/images/your-ideal-motorcycle-challenge/bg.png'
    ),
    startButtonTitle: 'Поїхали!',
    questions: [
      {
        id: 1,
        title: 'Які слова якнайкраще тебе\n описують?',
        answerList: [
          {
            id: 1.1,
            title: 'А) Спокійний та легкий на підйом',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/1.1.png'
            )
          },
          {
            id: 1.2,
            title: 'Б) Кмітливий та дотепний',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/1.2.png'
            )
          },
          {
            id: 1.3,
            title: 'В) Невпинний та енергійний',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/1.3.png'
            )
          }
        ]
      },
      {
        id: 2,
        title: 'Що з цих речей найбільш\n важливе для тебе?',
        answerList: [
          {
            id: 2.1,
            title: 'А) Комфорт',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/2.1.png'
            )
          },
          {
            id: 2.2,
            title: 'Б) Стиль',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/2.2.png'
            )
          },
          {
            id: 2.3,
            title: 'В) Швидкість',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/2.3.png'
            )
          }
        ]
      },
      {
        id: 3,
        title: 'Як ти уявляєш свою\n поїздку?',
        answerList: [
          {
            id: 3.1,
            title: 'А) Неспішна поїздка містом',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/3.1.png'
            )
          },
          {
            id: 3.2,
            title: 'Б) Драйвовий заїзд з музикою',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/3.2.png'
            )
          },
          {
            id: 3.3,
            title: 'В) Гонки по гірських серпантинах',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/3.3.png'
            )
          }
        ]
      },
      {
        id: 4,
        title: 'Який твій улюблений час\n доби для пригод?',
        answerList: [
          {
            id: 4.1,
            title: 'А) Ранок, коли мало людей',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/4.1.png'
            )
          },
          {
            id: 4.2,
            title: 'Б) Вечір, коли все навколо сяє',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/4.2.png'
            )
          },
          {
            id: 4.3,
            title: 'В) Будь-який час, головне — рух',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/4.3.png'
            )
          }
        ]
      },
      {
        id: 5,
        title: 'Як ти реагуєш на неочікувані\n ситуації в дорозі?',
        answerList: [
          {
            id: 5.1,
            title: 'А) Спокійно ухвалюю зважене рішення',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/5.1.png'
            )
          },
          {
            id: 5.2,
            title: 'Б) Шукаю креативний вихід',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/5.2.png'
            )
          },
          {
            id: 5.3,
            title: 'В) Дію рішуче й емоційно',
            image: getImageFromS3(
              '/images/your-ideal-motorcycle-challenge/5.3.png'
            )
          }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Скутер',
        description:
          'Тобі підійде скутер — легкий, компактний, зручний. Ідеально підходить для для насолоди кожною поїздкою.',
        image: getImageFromS3(
          '/images/your-ideal-motorcycle-challenge/result1.png'
        )
      },
      {
        id: 2,
        title: 'Спортивний мотоцикл',
        description:
          'Ти — справжній любитель стилю та швидкості! Спортивний мотоцикл відображає твою енергію та бажання бути в центрі уваги.',
        image: getImageFromS3(
          '/images/your-ideal-motorcycle-challenge/result2.png'
        )
      },
      {
        id: 3,
        title: 'Круїзер',
        description:
          'Ти створений для довгих подорожей із вітерцем. \n' +
          'Круїзер — це символ свободи, сили і впевненості.',
        image: getImageFromS3(
          '/images/your-ideal-motorcycle-challenge/result3.png'
        )
      }
    ]
  },
  {
    id: 'what-is-your-vibe',
    title: 'Який твій вайб',
    description:
      'Ти цікава та неординарна особистість, яка любить підкорювати нові вершини.\n' +
      'Дізнайся, яким тебе бачить оточення.',
    backgroundImage: getImageFromS3(
      '/images/what-is-your-vibe-challenge/bg.png'
    ),
    startButtonTitle: 'Поїхали!',
    questions: [
      {
        id: 1,
        title: 'Чим ти любиш\n наповнювати свій день?',
        answerList: [
          {
            id: 1.1,
            title: 'А) Мистецтвом та креативом',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/1.1.png')
          },
          {
            id: 1.2,
            title: 'Б) Природою',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/1.2.png')
          },
          {
            id: 1.3,
            title: 'В) Ритмом доріг',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/1.3.png')
          }
        ]
      },
      {
        id: 2,
        title: 'Яким моментом доби ти\n найбільше насолоджуєшся?',
        answerList: [
          {
            id: 2.1,
            title: 'А) Вітряним ранком',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/2.1.png')
          },
          {
            id: 2.2,
            title: 'Б) Теплим обіднім часом',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/2.2.png')
          },
          {
            id: 2.3,
            title: 'В) Драйвовою ніччю',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/2.3.png')
          }
        ]
      },
      {
        id: 3,
        title: 'Яка стихія тобі\n  ближча?',
        answerList: [
          {
            id: 3.1,
            title: 'А) Повітря — легкість і рух',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/3.1.png')
          },
          {
            id: 3.2,
            title: 'Б) Земля — надійність і спокій',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/3.2.png')
          },
          {
            id: 3.3,
            title: 'В) Вогонь — пристрасть і сила',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/3.3.png')
          }
        ]
      },
      {
        id: 4,
        title: 'Що ти цінуєш у\n людях?',
        answerList: [
          {
            id: 4.1,
            title: 'А) Творче мислення',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/4.1.png')
          },
          {
            id: 4.2,
            title: 'Б) Щирість і доброту',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/4.2.png')
          },
          {
            id: 4.3,
            title: 'В) Впевненість і авантюризм',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/4.3.png')
          }
        ]
      },
      {
        id: 5,
        title: 'Що тебе найбільше\n надихає?',
        answerList: [
          {
            id: 5.1,
            title: 'А) Гарний фільм чи книжка',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/5.1.png')
          },
          {
            id: 5.2,
            title: 'Б) Звуки природи',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/5.2.png')
          },
          {
            id: 5.3,
            title: 'В) Нові місця і пригоди',
            image: getImageFromS3('/images/what-is-your-vibe-challenge/5.3.png')
          }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Ловець свободи',
        description:
          'Ти — втілення творчості і креативу. Оточення вважає, що ти можеш зробити кожен день незабутнім завдяки ідеям та особливому погляду на світ.',
        image: getImageFromS3('/images/what-is-your-vibe-challenge/result1.png')
      },
      {
        id: 2,
        title: 'Натхненний сміливець',
        description:
          'Твій світогляд і щирість роблять тебе унікальним. Оточення цінує твою доброту і вміння знаходити красу навіть у простих речах.',
        image: getImageFromS3('/images/what-is-your-vibe-challenge/result2.png')
      },
      {
        id: 3,
        title: 'Мисливець за автентичністю',
        description:
          'Ти — енергійна і відважна особистість, яка завжди прагне більшого. Оточення бачить у тобі лідера і шукача, який не боїться долати перешкоди.',
        image: getImageFromS3('/images/what-is-your-vibe-challenge/result3.png')
      }
    ]
  },
  {
    id: 'where-wind-take',
    title: 'Куди занесе вітер',
    description: 'Ти коли-небудь мріяв політатина повітряній кулі?',
    backgroundImage: getImageFromS3('/images/where-wind-take-challenge/bg.png'),
    subDescription:
      'Відповідай на питання \n' + 'та дізнайся, куди вона тебе \n' + 'занесе',
    startButtonTitle: 'Поїхали!',
    questions: [
      {
        id: 1,
        title: 'Який пейзаж тобі\n подобається найбільше?',
        answerList: [
          {
            id: 1.1,
            title: 'А) Ліси та чисті озера',
            image: getImageFromS3('/images/where-wind-take-challenge/1.1.png')
          },
          {
            id: 1.2,
            title: 'Б) Гори та долини',
            image: getImageFromS3('/images/where-wind-take-challenge/1.2.png')
          },
          {
            id: 1.3,
            title: 'В) Пустелі та піщані дюни',
            image: getImageFromS3('/images/where-wind-take-challenge/1.3.png')
          }
        ]
      },
      {
        id: 2,
        title: 'Які твої ідеальні\n мандри?',
        answerList: [
          {
            id: 2.1,
            title: 'А) Релаксуючі',
            image: getImageFromS3('/images/where-wind-take-challenge/2.1.png')
          },
          {
            id: 2.2,
            title: 'Б) Відважні',
            image: getImageFromS3('/images/where-wind-take-challenge/2.2.png')
          },
          {
            id: 2.3,
            title: 'В) Захоплюючі',
            image: getImageFromS3('/images/where-wind-take-challenge/2.3.png')
          }
        ]
      },
      {
        id: 3,
        title: 'Що тебе найбільше\n приваблює у поїздках?',
        answerList: [
          {
            id: 3.1,
            title: 'А) Спокій і гармонія',
            image: getImageFromS3('/images/where-wind-take-challenge/3.1.png')
          },
          {
            id: 3.2,
            title: 'Б) Відчуття виклику і висоти',
            image: getImageFromS3('/images/where-wind-take-challenge/3.2.png')
          },
          {
            id: 3.3,
            title: ') Екзотика \n' + 'і невідомість',
            image: getImageFromS3('/images/where-wind-take-challenge/3.3.png')
          }
        ]
      },
      {
        id: 4,
        title: 'Що б ти взяв\n у подорож?',
        answerList: [
          {
            id: 4.1,
            title: 'А) Плед і улюблену книжку',
            image: getImageFromS3('/images/where-wind-take-challenge/4.1.png')
          },
          {
            id: 4.2,
            title: 'Б) Туристичний рюкзак',
            image: getImageFromS3('/images/where-wind-take-challenge/4.2.png')
          },
          {
            id: 4.3,
            title: 'В) Сонцезахисні окуляри і карту',
            image: getImageFromS3('/images/where-wind-take-challenge/4.3.png')
          }
        ]
      },
      {
        id: 5,
        title: 'Як ти відчуваєш себе серед\n незнайомого?',
        answerList: [
          {
            id: 5.1,
            title: 'А) Розслаблено',
            image: getImageFromS3('/images/where-wind-take-challenge/5.1.png')
          },
          {
            id: 5.2,
            title: 'Б) Допитливо, хочу пізнати більше',
            image: getImageFromS3('/images/where-wind-take-challenge/5.2.png')
          },
          {
            id: 5.3,
            title: 'В) Драйвово, з’являється бажання ризикувати',
            image: getImageFromS3('/images/where-wind-take-challenge/5.3.png')
          }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Море',
        description:
          'Твоя повітряна куля несе тебе до хвиль. м’який бриз і шум води — ось що тобі потрібно, щоб відчути гармонію.',
        image: getImageFromS3('/images/where-wind-take-challenge/result1.png')
      },
      {
        id: 2,
        title: 'Гори',
        description:
          'Твій шлях пролягає до вершин, де кожен крок є новим викликом. Гори — твоє місце сили і відваги.',
        image: getImageFromS3('/images/where-wind-take-challenge/result2.png')
      },
      {
        id: 3,
        title: 'Дюни',
        description:
          'Твоя куля несе тебе в загадкові пустелі, девсе наповнене спокоєм, екзотикою і таємницями.',
        image: getImageFromS3('/images/where-wind-take-challenge/result3.png')
      }
    ]
  }
];
