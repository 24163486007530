import React, { useState } from 'react';
import { getImageFromS3 } from '../../../imageService';
import WordGame from '../../word-game';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';

const ThreePicturesOneWordChallenge = ({
  setScreenNumber,
  setNextScreenNumber
}) => {
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [isAfterResultScreen, setIsAfterResultScreen] = useState(false);

  return (
    <>
      {!isResultScreen && !isAfterResultScreen && (
        <WordGame
          type="chesterfield"
          setScreenNumber={setScreenNumber}
          setNextScreenNumber={() => {
            setIsResultScreen(true);
          }}
          handleEndChallenge={() => {
            setIsResultScreen(true);
          }}
          backgroundImage={`url("${getImageFromS3(
            '/images/word-challenge/chesterfield/bg.png'
          )}")`}
        />
      )}
      {isResultScreen && !isAfterResultScreen && (
        <div
          className={styles.resultContainer}
          style={{
            backgroundImage: `url(${getImageFromS3(
              '/images/word-challenge/chesterfield/bg.png'
            )})`
          }}
        >
          <div style={{ padding: '20px 30px 0' }}>
            <HeadContainer setScreenNumber={setScreenNumber} />
          </div>
          <p className={styles.headTitle}>Результат</p>
          <img
            src={getImageFromS3(
              '/images/word-challenge/chesterfield/result.png'
            )}
            alt="result-image"
          />
          <div className={styles.description}>
            <p>Вітаємо! Ти на відмінно впорався з кожним завданням!</p>
            <p>
              Це означає, що тобі під силу будь-яка пригода, а складнощі ти
              сприймаєш як можливість вийти на новий рівень.
            </p>
          </div>
          <button
            onClick={() => {
              setIsResultScreen(false);
              setIsAfterResultScreen(true);
            }}
          >
            <span>Далі</span>
          </button>
        </div>
      )}
      {isAfterResultScreen && !isResultScreen && (
        <div
          className={styles.afterResultContainer}
          style={{
            backgroundImage: `url(${getImageFromS3(
              '/images/word-challenge/chesterfield/bg.png'
            )})`
          }}
        >
          <div style={{ padding: '20px 30px 0' }}>
            <HeadContainer setScreenNumber={setScreenNumber} />
          </div>
          <img
            src={getImageFromS3(
              '/images/word-challenge/chesterfield/after-result-image.png'
            )}
            alt="result-image"
          />
          <div className={styles.description}>
            <p>Хочеш ще більше завдань?</p>
            <p>
              Заходь на нашу сторінку щоразу, як матимеш бажання розважитись.
            </p>
          </div>
          <button
            onClick={() => {
              setIsResultScreen(false);
              setIsAfterResultScreen(false);
              setNextScreenNumber();
            }}
          >
            <span>Далі</span>
          </button>
        </div>
      )}
    </>
  );
};

export default ThreePicturesOneWordChallenge;
