import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ChooseStepBtn.module.css';
import { ReactComponent as BtnImage } from '../../icons/next_btn.svg';
import { ReactComponent as BtnImageSkip } from '../../icons/skip-btn-bg.svg';
import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg';
import { ReactComponent as ArrowRightWhite } from '../../icons/arrow-right-white.svg';
let cx = classNames.bind(styles);

const getButtonBackground = (style) => {
  switch (style) {
    case 'skip-video-opacity': {
      return <img src="/images/next-button.png" alt="next-button" />;
    }
    case 'skip-video': {
      return <BtnImageSkip />;
    }
    default: {
      return <BtnImage />;
    }
  }
};

const getTextArrow = (style) => {
  switch (style) {
    case 'skip-video-opacity': {
      return <ArrowRightWhite className={styles.arrow} />;
    }
    default: {
      return <ArrowRight className={styles.arrow} />;
    }
  }
};

export default function ChooseStepBtn({
  execute,
  direction,
  novel,
  style,
  hasTextArrow,
  skipButtonBottomPosition
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 10000);
  }, []);

  let btnSvgBox = cx({
    btnSvg: true,
    back: direction === 'back'
  });

  let btnBox = cx({
    directionBtn: true,
    back: direction === 'back'
  });

  let nameBox = cx({
    name: true,
    back: direction === 'back'
  });

  let btnSize = cx({
    bigB: true
  });

  return (
    isVisible && (
      <button
        type="button"
        className={`${btnBox} ${btnSize} ${styles[style]}`}
        style={{ bottom: skipButtonBottomPosition }}
        onClick={(e) => execute(e)}
      >
        {getButtonBackground(style)}
        {style !== 'skip-video-opacity' && (
          <span className={nameBox}>
            {direction === 'back' ? 'Назад' : 'Далі'}
            {hasTextArrow && getTextArrow(style)}
          </span>
        )}
      </button>
    )
  );
}
