import React, { useState } from 'react';
import MenuScreen from '../common/menu-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import { getImageFromS3 } from '../imageService';
import TripleChoiceChallenge from '../common/challenge-component/triple-choice-challenge';
import RhythmOfYourEveningChallenge from '../common/challenge-component/rhythm-of-your-evening-challenge';
import ResidentsWhichCountryChallenge from '../common/challenge-component/residents-which-country-challenge';
import TasteExperimenterChallenge from '../common/challenge-component/taste-experimenter-challenge';
import YourEnergyForDayChallenge from '../common/challenge-component/your-energy-for-day-challenge';
import FindExtraChallenge from '../common/challenge-component/find-extra-challenge';
import WhatDoOnWeekendChallenge from '../common/challenge-component/what-do-on-weekend-challenge';
import SohoTourChallenge from '../common/challenge-component/soho-tour-challenge';
import ThreePicturesOneWordChallenge from '../common/challenge-component/three-pictures-one-word-challenge';
import DndChallenge from '../common/challenge-component/dnd-challenge';
import OnlyImageChallenge from '../common/challenge-component/only-image-challenge';
import {
  percentageOfYourBohemianChallenge,
  percentageOfYourBohemianResultList,
  streetArtChallenge
} from '../consts/chesterfield-challenge';
import TwoImageSelectChallenge from '../common/challenge-component/two-image-select-challenge';
import PercentageOfYourBohemianChallenge from '../common/challenge-component/percentage-of-your-bohemian-challenge';

const menu = [
  {
    id: 'your-ideal-motorcycle',
    screenNumber: 2,
    name: 'Твій ідеальний мотоцикл',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'what-is-your-vibe',
    screenNumber: 3,
    name: 'Який твій вайб',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'where-wind-take',
    screenNumber: 4,
    name: 'Куди занесе вітер',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'rhythm-of-your-evening',
    screenNumber: 5,
    name: 'Ритм твого вечора',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'residents-which-country',
    screenNumber: 6,
    name: 'Про жителів якої країни йде мова',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'taste-experimenter',
    screenNumber: 7,
    name: 'Смаковий експерементатор',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'your-energy-for-day',
    screenNumber: 8,
    name: 'Твоя енергія на день',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'find-extra',
    screenNumber: 9,
    name: 'Знайди зайве',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'what-do-on-weekend',
    screenNumber: 10,
    name: 'Чим зайнятись на вихідних',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'youth-slang',
    screenNumber: 11,
    name: 'Наскільки добре ти знаєш молодіжний сленг',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'soho-tour',
    screenNumber: 12,
    name: 'Екскурсія по Сохо',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'three-picture-one-word',
    screenNumber: 13,
    name: 'Три картинки й одне слово',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'from-which-years-trend-borrowed',
    screenNumber: 14,
    name: 'З яких років запозичений тренд',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'from-which-years-trend-borrowed',
    screenNumber: 15,
    name: 'Стрит-арт',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  }
  // {
  //   id: 'percentage-of-your-bohemian',
  //   screenNumber: 16,
  //   name: 'Відсоток твоєї богемності',
  //   icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  // }
];

const TestChallenges = ({ setAppNumber }) => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);

  const getScreen = (number, cb) => {
    switch (number) {
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/prl-lpe/bg.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <TripleChoiceChallenge
            setScreenNumber={setScreenNumber}
            challengeId="your-ideal-motorcycle"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 3: {
        return (
          <TripleChoiceChallenge
            setScreenNumber={setScreenNumber}
            challengeId="what-is-your-vibe"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 4: {
        return (
          <TripleChoiceChallenge
            setScreenNumber={setScreenNumber}
            challengeId="where-wind-take"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 5: {
        return (
          <RhythmOfYourEveningChallenge
            setScreenNumber={setScreenNumber}
            challengeId="rhythm-of-your-evening"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 6: {
        return (
          <ResidentsWhichCountryChallenge
            setScreenNumber={setScreenNumber}
            challengeId="residents-which-country"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 7: {
        return (
          <TasteExperimenterChallenge
            setScreenNumber={setScreenNumber}
            challengeId="taste-experimenter"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 8: {
        return (
          <YourEnergyForDayChallenge
            setScreenNumber={setScreenNumber}
            challengeId="your-energy-for-day"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 9: {
        return (
          <FindExtraChallenge
            setScreenNumber={setScreenNumber}
            challengeId="find-extra"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 10: {
        return (
          <WhatDoOnWeekendChallenge
            setScreenNumber={setScreenNumber}
            challengeId="what-do-on-weekend"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 11: {
        return (
          <ResidentsWhichCountryChallenge
            setScreenNumber={setScreenNumber}
            challengeId="youth-slang"
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 12: {
        return (
          <SohoTourChallenge
            setScreenNumber={setScreenNumber}
            afterResultButtonScreen={() => setScreenNumber(1)}
          />
        );
      }
      case 13: {
        return (
          <ThreePicturesOneWordChallenge
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              '/images/word-challenge/chesterfield/bg.png'
            )}")`}
          />
        );
      }
      case 14: {
        return (
          <DndChallenge
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              '/images/word-challenge/chesterfield/bg.png'
            )}")`}
          />
        );
      }
      case 15: {
        return (
          <OnlyImageChallenge
            style="street-art"
            welcomeEllipse={getImageFromS3(
              'images/street-art-challenge/circle.png'
            )}
            resultTitle=""
            resultDescription={`ДО НОВИХ ЗУСТРІЧЕЙ!\nСподіваємось, що тобі було цікаво й пізнавально.\nТепер ти можеш “блиснути” знаннями й подискутувати про стріт-арт зі знайомими.`}
            resultImage={getImageFromS3(
              '/images/street-art-challenge/result.png'
            )}
            welcomeTitle="Стрит-арт"
            welcomeButtonTitle="Гортай, щоб побачити більше"
            welcomeDescription={`Стрит-арт — мистецтво, яке ми ледь не щодня зустрічаємо на стінах, асфальті чи у найнеочікуваніших куточках вулиці.\n\n Ми зібрали для тебе цікаві факти, які допоможуть тобі більше відкрити цей культурний феномен.`}
            setScreenNumber={cb}
            challengeList={streetArtChallenge}
            setScreenNumberAfterResult={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              '/images/street-art-challenge/bg.png'
            )}")`}
          />
        );
      }
      case 16: {
        return (
          <PercentageOfYourBohemianChallenge
            setScreenNumber={cb}
            challengeList={percentageOfYourBohemianChallenge}
            backgroundImage={`url("${getImageFromS3(
              'images/percentage-of-your-bohemian-challenge/background.png'
            )}")`}
            resultList={percentageOfYourBohemianResultList}
            setAfterResultScreen={() => setScreenNumber(20)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="test-challenges-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default TestChallenges;
