import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const partners = {
  rozetka:
    'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/?utm_source=smsCDAmarMPM25',
  cig: 'https://cig-poshta.in.ua/product-tag/philip-morris-25-uk/?utm_source=smsmpm25'
};

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headContainer}>
          <img
            src={getImageFromS3('images/sms-mpm25/head-background.png')}
            alt="headBackground"
            className={styles.headBackground}
          />
          <img
            src={getImageFromS3('images/sms-mpm25/headLogo.png')}
            alt="logo"
            className={styles.headLogo}
          />
          <div className={styles.packContainer}>
            <div className={styles.packItem} style={{ marginLeft: 10 }}>
              <img
                src={getImageFromS3('images/sms-mpm25/red-25.png')}
                alt="headLogo"
                className={styles.packImage}
              />
              <p>
                Philip Morris<br></br>Red 25
              </p>
            </div>
            <div className={styles.packItem}>
              <img
                src={getImageFromS3('images/sms-mpm25/blue-25.png')}
                alt="headLogo"
                className={styles.packImage}
                style={{ width: 108 }}
              />
              <p style={{ marginTop: 8 }}>
                Philip Morris<br></br>Blue 25
              </p>
            </div>
          </div>
          <img
            src={getImageFromS3('images/sms-mpm25/priceGifContainer.gif')}
            alt="gif-image"
            className={styles.priceGifContent}
          />
        </div>
        <div
          className={styles.buyContainer}
          style={{
            backgroundImage: `url(${getImageFromS3(
              'images/sms-mpm25/price-background.png'
            )})`
          }}
        >
          <div className={styles.priceContainer}>
            <p>
              <span>
                4,6<span>грн</span>
              </span>
              в перерахунку на 1 сигарету
            </p>
          </div>
          <p className={styles.priceDescription}>
            *зазначено задекларовану максимальну роздрібну ціну з урахуванням
            акцизного податку на роздрібний продаж тютюнових виробів станом на
            березень 2025 року
          </p>
          <div className={styles.whereBuyContainerWrapper}>
            <div className={styles.whereBuyContainer}>
              <p className={styles.title}>Де купити?</p>
              <p className={styles.description}>
                <b>Philip Morris Red 25</b> та <b>Philip Morris Blue 25</b>{' '}
                можна знайти у роздрібних точках продажу в твоєму місті або на
                офіційних онлайн сервісах, зокрема:
              </p>
              <div className={styles.partnersButtons}>
                <button className={styles.link}>
                  <a href={partners ? partners.cig : ''}>Cig-Poshta</a>
                </button>
                <button className={styles.link}>
                  <a href={partners ? partners.rozetka : ''}>Rozetka</a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formatContainer}>
          <img
            src={getImageFromS3('images/sms-mpm25/format-background.png')}
            alt="formatBackground"
            className={styles.formatBackground}
          />
          <p className={styles.title}>25 сигарет у "Кінг Сайз" форматі</p>
          <div className={styles.packContainer}>
            <img
              src={getImageFromS3('images/sms-mpm25/red-front-pack.png')}
              alt="red-pack"
              className={styles.packImage}
            />
            <img
              src={getImageFromS3('images/sms-mpm25/blue-front-pack.png')}
              alt="blue-pack"
              className={styles.packImage}
            />
          </div>
          <div className={styles.characteristicsContainer}>
            <div className={styles.firstContainer}>
              <p>9 мг</p>
              <p>вміст смол</p>
              <p>6 мг</p>
            </div>
            <div className={styles.secondContainer}>
              <p>0.7 мг</p>
              <p>вміст нікотину</p>
              <p>0.5 мг</p>
            </div>
          </div>
          <div className={styles.characteristicsItemsContainer}>
            <div className={styles.item}>
              <img
                src={getImageFromS3('images/sms-mpm25/filter.png')}
                alt="filter"
              />
              <p>
                Ацетатний<br></br> фільтр
              </p>
            </div>
            <div className={styles.item}>
              <img
                src={getImageFromS3('images/sms-mpm25/tobacco.png')}
                alt="tobacco"
              />
              <p>
                Бленд з трьох<br></br> сортів тютюну
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав лист, оскільки залишав запит на отримання інформації про
        продукцію компаній Philip Morris в Україні.<br></br>
        <a href="/unsubscribe?returnLink=25-sms-mpm25">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
