import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';
import classNames from 'classnames';
import React from 'react';

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundImage }}>
        <div className={styles.headContainer}>
          <p>Від бренду</p>
          <img
            src={getImageFromS3('images/sms-che/headTitle.png')}
            alt="head-title"
          />
        </div>
        <img
          className={styles.packImage}
          src={getImageFromS3('images/sms-che/packContainer.png')}
          alt="pack-container"
        />
        <div className={styles.characteristicContainer}>
          <img
            src={getImageFromS3('images/sms-che/back-animation.gif')}
            className={styles.background}
            alt="background"
          />
          <img
            src={getImageFromS3('images/sms-che/tobaccoIcon.png')}
            alt="tobacco-icon"
          />
          <p>
            <b>Оновлена</b> тютюнова суміш
          </p>
        </div>
        <div
          className={classNames(
            styles.characteristicContainer,
            styles.secondCharacteristicContainer
          )}
        >
          <img
            src={getImageFromS3('images/sms-che/back-animation-2.gif')}
            className={styles.background}
            alt="background"
          />
          <p>
            <b>Фільтр</b> з повітряною камерою
          </p>
          <img
            src={getImageFromS3('images/sms-che/filterIcon.png')}
            alt="filter-icon"
          />
        </div>
        <div
          className={classNames(
            styles.characteristicContainer,
            styles.thirdCharacteristicContainer
          )}
        >
          <img
            src={getImageFromS3('images/sms-che/back-animation.gif')}
            className={styles.background}
            alt="background"
          />
          <img
            src={getImageFromS3('images/sms-che/packFormat.png')}
            alt="pack-icon"
          />
          <p>
            <b>Прогресивний</b> компактний формат
          </p>
        </div>
        {/*<div className={styles.buyContainer}>*/}
        {/*  <img*/}
        {/*    src={getImageFromS3('images/sms-che/buy-background.png')}*/}
        {/*    className={styles.background}*/}
        {/*    alt="buy-background"*/}
        {/*  />*/}
        {/*  <p className={styles.title}>*/}
        {/*    Хочеш отримувати новини від Філіп Морріс Україна?*/}
        {/*  </p>*/}
        {/*  <p className={styles.description}>*/}
        {/*    Проходь невелике опитування та отримуй 20 грн на мобільний за*/}
        {/*    витрачений час.*/}
        {/*  </p>*/}
        {/*  <div className={styles.buttonContainer}>*/}
        {/*    <div>*/}
        {/*      <a href="https://www.scanpack.com/ua-reg?utm_campaign=25UA-KV2&returned=true">*/}
        {/*        Пройти опитування*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
    </div>
  );
};

export default HomeScreen;
