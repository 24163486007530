import React from 'react';
import styles from '../common/qr-error-screen/index.module.scss';
import { getImageFromS3 } from '../imageService';

const QrError = () => {
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/common/qr-error-screen/violet-background.png'
        )}")`
      }}
    >
      <p className={styles.title}>
        Упс!<br></br>Виникла помилка з QR-кодом:(
      </p>
      <p className={styles.description}>
        Відскануй QR-код повторно у представника бренду
      </p>
    </div>
  );
};

export default QrError;
