import React, { useState, useEffect } from 'react';
import styles from '../components/pmn/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import InfoScreen from '../components/lm-d-s/info-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import FactoryScreen from '../common/factory-screen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import SelectSeasonChallenge from '../common/challenge-component/select-season-challenge';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';
import ResidentsWhichCountryChallenge from '../common/challenge-component/residents-which-country-challenge';

const menuList = [
  {
    id: 'challenge',
    screenNumber: 7,
    name: 'Челендж березня',
    icon: 'images/common/menu-icon/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 12,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 0,
    name: 'Характеристики сигарет',
    icon: 'images/common/menu-icon/characteristic-menu-icon.svg'
  },
  {
    id: 'about',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/common/menu-icon/info-menu-icon.svg'
  },
  {
    id: 'factory',
    screenNumber: 4,
    name: 'Нова фабрика на Львівщині',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review_main',
    screenNumber: 5,
    name: 'Залишити відгук про продукт',
    icon: 'images/common/menu-icon/review-menu-icon.svg'
  }
];

const selectedPack = {
  title: 'L&M Blue Label',
  pack: getImageFromS3('images/packs/lm/blue-label.png'),
  tobaccoFirst: 'Вміст смол, мг - 6',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/bond/filter.png'),
  background: `url("${getImageFromS3(
    'images/lm-double-splash/menu-background.png'
  )}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size (Кінг сайз)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          Класичний ацетатний фільтр з високоякісної целюлози, який затримує
          тверді частинки сигаретного диму.
        </span>
      ),
      bordered: false
    }
  ]
};

export default function LMB() {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('LM-B');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -2: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/factory-video.mp4')}
            setScreenNumber={cb}
            nextScreenNumber={1}
            changeScreenImmediately
            skipScreenImmediately
            controls
            buttonStyle="skip-video"
            hasButtonArrow
          />
        );
      }
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(11);
            }}
            handleBackButton={() => setScreenNumber(0)}
          />
        );
      }
      case 0: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            newMixtureText={selectedPack?.newMixtureText}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            characteristicListTitle={selectedPack?.characteristicListTitle}
            nextButtonTitle="Далі"
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menuList}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 4: {
        return (
          <FactoryScreen
            setScreenNumber={cb}
            backButtonClick={() => setScreenNumber(1)}
            nextButtonClick={() => setScreenNumber(9)}
          />
        );
      }
      case 5: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 6: {
        return (
          <SelectSeasonChallenge
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
            setScreenNumber={setScreenNumber}
            type="harmony_in_self-knowledge-lm"
            style="soho"
            setNextScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 7: {
        return (
          <ResidentsWhichCountryChallenge
            setScreenNumber={setScreenNumber}
            challengeId="residents-which-country"
            afterResultButtonScreen={() => setScreenNumber(9)}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
          // <WelcomeChallengeScreen
          //   setScreenNumber={setScreenNumber}
          //   backgroundImage={`url("${getImageFromS3(
          //     'images/lm-double-splash/background-new.png'
          //   )}")`}
          //   title={
          //     <>
          //       Гармонія в <br></br>самопізнанні
          //     </>
          //   }
          //   description={
          //     <>
          //       Ми підготували невеличкий<br></br>тест, який покаже твій тип
          //       <br></br>особистості.
          //     </>
          //   }
          //   buttonTitle="Пройти"
          //   setNextScreenNumber={() => setScreenNumber(6)}
          // />
        );
      }
      case 9: {
        return (
          <FinalScreen
            setScreenNumber={cb}
            setScreenNumberToReview={() => setScreenNumber(5)}
            withReviewButton
            isUpdatedApp
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 11: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(9)}
          />
        );
      }
      case 12: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
}
