import React, { useState, useEffect, useRef } from 'react';
import { challengeComponent } from './content';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';

const WhatDoOnWeekendChallenge = ({
  setScreenNumber,
  challengeId,
  afterResultButtonScreen
}) => {
  const challengeList = challengeComponent.find(
    (item) => item?.id === challengeId
  );
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isAfterResultScreen, setIsAfterResultScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const imageUrls = challengeList?.imageList?.map((item) => item.url);
  const interval = 200;
  const preloadedImagesRef = useRef([]);
  const timerRef = useRef(null);

  useEffect(() => {
    if (preloadedImagesRef.current.length === 0) {
      preloadedImagesRef.current = imageUrls.map((url) => {
        const img = new Image();
        img.src = url;
        return img;
      });
    }
  }, [imageUrls]);

  useEffect(() => {
    if (!isRunning || preloadedImagesRef.current.length === 0) return;

    const updateIndex = () => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % preloadedImagesRef.current.length
      );
      timerRef.current = setTimeout(updateIndex, interval);
    };

    timerRef.current = setTimeout(updateIndex, interval);
    return () => clearTimeout(timerRef.current);
  }, [isRunning, interval]);

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${challengeList.backgroundImage})` }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isAfterResultScreen && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={challengeList?.welcomeBackgroundIcon}
              alt="welcome-icon"
            />
            <p className={styles.title}>{challengeList?.title}</p>
          </div>
          <p className={styles.description}>{challengeList?.description}</p>
          <button onClick={() => setIsWelcomeScreen(false)}>
            {challengeList?.startButtonTitle}
          </button>
        </div>
      )}
      {!isWelcomeScreen && !isAfterResultScreen && (
        <div className={styles.challengeContainer}>
          <div
            onClick={() => setIsRunning(false)}
            style={{ cursor: 'pointer' }}
          >
            {preloadedImagesRef.current.length > 0 && (
              <img
                src={preloadedImagesRef.current[currentIndex].src}
                alt="carousel"
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </div>
          {!isRunning && (
            <div className={styles.buttonContainer}>
              <div
                className={styles.leftButton}
                onClick={() => {
                  setIsWelcomeScreen(true);
                }}
              >
                <span>Назад</span>
              </div>
              <div
                className={styles.rightButton}
                onClick={() => {
                  setIsAfterResultScreen(true);
                }}
              >
                <span>Далі</span>
              </div>
            </div>
          )}
        </div>
      )}
      {isAfterResultScreen && (
        <div className={styles.afterResultContainer}>
          <img
            src={challengeList?.afterResultContent?.image}
            alt="after-result-image"
          />
          <p className={styles.title}>
            {challengeList?.afterResultContent?.title}
          </p>
          <p className={styles.description}>
            {challengeList?.afterResultContent?.description}
          </p>
          <button
            onClick={() => {
              setIsAfterResultScreen(false);
              setIsWelcomeScreen(true);
              afterResultButtonScreen();
            }}
          >
            <span>{challengeList?.afterResultContent?.nextButtonText}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default WhatDoOnWeekendChallenge;
