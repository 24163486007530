import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';
import { getImageFromS3 } from '../../../../imageService';
import apiService from '../../../../utils/apiService';

const OtpComponent = ({
  setScreenNumber,
  handleChangePhoneNumber,
  successSendOtp
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timeLeft, setTimeLeft] = useState(60);
  const [isResendAvailable, setIsResendAvailable] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendAvailable(true);
    }
  }, [timeLeft]);

  const handleResend = async () => {
    if (resendCount < 2) {
      try {
        setResendCount(resendCount + 1);
        const id = localStorage.getItem('rw_id');
        await apiService.get(`resend/${id}`);
        setTimeLeft(60);
        setIsResendAvailable(false);
      } catch (e) {
        setIsResendAvailable(false);
      }
    } else {
      setIsResendAvailable(false);
    }
  };

  const handleChange = (element, index) => {
    const value = element.value;

    if (value.length === 6) {
      const newOtp = value.split('').slice(0, 6);
      setOtp(newOtp);
      document
        .querySelectorAll('input[type="number"]')
        .forEach((input, idx) => {
          input.value = newOtp[idx];
        });
      return;
    }

    if (!isNaN(value) && value.length === 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const onSubmit = async () => {
    try {
      const id = localStorage.getItem('rw_id');
      await apiService.post(`confirm/${id}`, {
        data: {
          code: parseInt(otp.join(''), 10)
        }
      });
      successSendOtp();
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      const abortController = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: abortController.signal
        })
        .then((otpCredential) => {
          if (otpCredential && otpCredential.code) {
            const codeArray = otpCredential.code.split('').slice(0, 6);
            setOtp(codeArray);
            document
              .querySelectorAll('input[type="number"]')
              .forEach((input, idx) => {
                input.value = codeArray[idx] || '';
              });
          }
        })
        .catch((err) => {
          console.error('OTP API Error:', err);
        });

      return () => abortController.abort();
    }
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/violet-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.content}>
        <p className={styles.title}>
          Ми надіслали повідомлення на номер 380*********
        </p>
        <p className={styles.description}>
          Для підтвердження номеру введи код із SMS
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className={styles.otpContainer}>
            {otp.map((data, index) => (
              <input
                key={index}
                type="number"
                maxLength="1"
                value={data}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => {
                  e.target.select();
                  setError(false);
                }}
                autoComplete="one-time-code"
              />
            ))}
          </div>
        </form>
        {resendCount < 2 &&
          (isResendAvailable ? (
            <button className={styles.resendButton} onClick={handleResend}>
              Отримати код
            </button>
          ) : (
            <div className={styles.timer}>
              Отримати код повторно через{' '}
              {String(Math.floor(timeLeft / 60)).padStart(2, '0')}:
              {String(timeLeft % 60).padStart(2, '0')}
            </div>
          ))}
        <p className={styles.changeNumber} onClick={handleChangePhoneNumber}>
          Змінити номер
        </p>
        <div className={styles.buttonContainer}>
          {error && <p className={styles.error}>Невірний код</p>}
          <button className={styles.menuButton} onClick={onSubmit}>
            Надіслати
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpComponent;
