import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const CircleTobaccoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g filter="url(#filter0_d_4_73)">
        <circle
          cx="6.5"
          cy="6.5"
          r="5.5"
          stroke="url(#paint0_linear_4_73)"
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4_73"
          x="0"
          y="0"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4_73"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4_73"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4_73"
          x1="11.7305"
          y1="2.79297"
          x2="1.87891"
          y2="10.5117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E5B3A" />
          <stop offset="0.510417" stopColor="#9D7A3F" />
          <stop offset="1" stopColor="#6E5B3A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundImage }}>
        <div className={styles.title}>
          <p>Привіт!</p>
          <p>
            Лімітована лінійка від Marlboro тепер доступна на ринку України.
          </p>
        </div>
        <img
          src={getImageFromS3('images/sms-mrl/head.gif')}
          alt="head-image"
          className={styles.headImage}
        />
        <img
          src={getImageFromS3('images/sms-mrl/title.png')}
          alt="title"
          className={styles.titleImage}
        />
        <p className={styles.limitedTitle}>Лімітована пропозиція</p>
        <img
          src={getImageFromS3('images/sms-mrl/pack.gif')}
          alt="pack"
          className={styles.packImage}
        />
        <p className={styles.packTitle}>Marlboro Gold Titanium</p>
        <p className={styles.tobaccoTitle}>
          Вміст смол, мг - 6 <br />
          Вміст нікотину, мг - 0.5
        </p>
        <p className={styles.formatTitle}>у “Кінг сайз” форматі</p>
        <div className={styles.filterContainer}>
          <div className={styles.filterTitle}>
            <p>Ацетатний фільтр</p>
            <p>у чорному дизайні</p>
          </div>
          <img
            src={getImageFromS3('images/sms-mrl/filter.gif')}
            alt="filter"
            className={styles.filterImage}
          />
        </div>
        <div className={styles.filterCharacteristicContainer}>
          <img
            src={getImageFromS3('images/sms-mrl/filter-icon.png')}
            alt="filter-icon"
            className={styles.filterIcon}
          />
          <p>Firm filter</p>
          <p>
            Ущільнений ацетатний фільтр, який краще тримає форму та менше
            розмокає
          </p>
        </div>
        <div className={styles.tobaccoContainer}>
          <p className={styles.tobaccoTitle}>Бленд з трьох сортів тютюну</p>
          <div className={styles.tobaccoCharacteristic}>
            <img
              src={getImageFromS3('images/sms-mrl/tobacco-icon.png')}
              alt="filter-icon"
              className={styles.tobaccoIcon}
            />
            <div className={styles.characteristicContainer}>
              {['Берлі', 'Вірджинія', 'Орієнтал'].map((item) => {
                return (
                  <div className={styles.characteristicItem}>
                    <CircleTobaccoIcon />
                    <p>{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/*<div className={styles.buyContainer}>*/}
        {/*  <p className={styles.title}>*/}
        {/*    Хочеш отримувати новини від Філіп Морріс Україна?*/}
        {/*  </p>*/}
        {/*  <p className={styles.description}>*/}
        {/*    Проходь невелике опитування та отримуй 20 грн на мобільний за*/}
        {/*    витрачений час.*/}
        {/*  </p>*/}
        {/*  <div className={styles.buttonContainer}>*/}
        {/*    <div>*/}
        {/*      <a href="https://www.scanpack.com/ua-reg?utm_campaign=25UA-KV1&returned=true">*/}
        {/*        Пройти опитування*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
    </div>
  );
};

export default HomeScreen;
