import React, { useState, useEffect } from 'react';
import WelcomeChallengeScreen from '../challenge-component/welcome-challenge-screen';
import Progress from './Progress.jsx';
import styles from './index.module.scss';
import global from '../index.module.scss';
import ResultInfoScreen from '../challenge-component/result-info-screen';
const START_STATE = 'start';
const IN_PROGRESS_STATE = 'in_progress';
const END_STATE = 'end';

const WordGame = ({
  setScreenNumber,
  setNextScreenNumber,
  backgroundImage,
  handleEndChallenge,
  type
}) => {
  const [state, setState] = useState(START_STATE);
  const [data, setData] = useState({
    challengeList: [],
    start: {},
    end: {}
  });
  const [currentStepIdx, setCurrentStepIdx] = useState(-1);
  const [history, setHistory] = useState([]);
  const [currentStepData, setCurrentStepData] = useState({
    value: null,
    state: { answerMap: [], itemsMap: [] }
  });

  useEffect(() => {
    (async () => {
      const { challengeList, start, end } = await import(`./utils/${type}.js`);
      const [_challengeList, _start, _end] = await Promise.all([
        challengeList,
        start,
        end
      ]);
      setData({
        challengeList: _challengeList,
        start: _start,
        end: _end
      });
    })();
  }, [type]);

  const getNewStepData = (idx) => {
    if (!data.challengeList || !data.challengeList?.length) return;
    if (!data.challengeList?.[idx]) return null;
    return {
      value: data?.challengeList[idx] || null,
      state: {
        answerMap:
          data?.challengeList?.[idx]?.answer?.split('')?.map((_) => false) ||
          [],
        itemsMap: data?.challengeList?.[idx]?.characters?.map((_) => true) || []
      }
    };
  };

  useEffect(() => {
    if (currentStepIdx < 0) {
      setState(START_STATE);
      return;
    }
    if (!history[currentStepIdx]) {
      const stepData = getNewStepData(currentStepIdx);
      if (!stepData && currentStepIdx > 0) {
        setState(END_STATE);
        if (handleEndChallenge) handleEndChallenge();
        return;
      }
      setHistory((prev) =>
        prev?.length > 0 ? [...prev, stepData] : [stepData]
      );
    } else {
      setCurrentStepData(history[currentStepIdx]);
    }
  }, [currentStepIdx]);

  useEffect(() => {
    if (history[currentStepIdx]) {
      setCurrentStepData(history[currentStepIdx]);
    }
  }, [history, currentStepIdx]);

  const handleStart = () => {
    if (!data.challengeList || !data.challengeList?.length) return;
    setCurrentStepIdx(0);
    setState(IN_PROGRESS_STATE);
  };

  const onStepSuccess = () => {};

  const onStepChange = ({ answerMap, itemsMap }) => {
    setHistory((hist) => {
      const _hist = [...hist];
      if (!_hist[currentStepIdx]) return _hist;
      _hist[currentStepIdx] = {
        value: _hist[currentStepIdx]?.value,
        state: { answerMap: answerMap || [], itemsMap: itemsMap || [] }
      };
      return _hist;
    });
  };

  const onClickPrev = () => {
    setCurrentStepIdx((_idx) => --_idx);
  };
  const onClick = () => {
    setCurrentStepIdx((_idx) => ++_idx);
  };

  switch (state) {
    case IN_PROGRESS_STATE: {
      return (
        <>
          <Progress
            type={type}
            data={currentStepData}
            onSuccess={onStepSuccess}
            onStepChange={onStepChange}
            setScreenNumber={setScreenNumber}
            backgroundImage={backgroundImage}
            stepIndex={currentStepIdx}
            stepsCount={data?.challengeList?.length || 0}
            footer={
              <div className={styles.navigation}>
                <button className={global.reviewButton} onClick={onClickPrev}>
                  Назад
                </button>
                {currentStepData?.state?.answerMap?.filter((item) => !item)
                  ?.length === 0 && (
                  <button className={global.menuButton} onClick={onClick}>
                    Далі
                  </button>
                )}
              </div>
            }
          />
        </>
      );
    }
    case END_STATE: {
      return (
        <ResultInfoScreen
          type={type}
          setScreenNumber={setScreenNumber}
          setNextScreenNumber={setNextScreenNumber}
          title={data?.end.title}
          description={data?.end.description}
          image={data?.end.image}
          backgroundImage={backgroundImage}
        />
      );
    }
    case START_STATE:
    default: {
      return (
        <WelcomeChallengeScreen
          style={type}
          setScreenNumber={setScreenNumber}
          backgroundImage={backgroundImage}
          title={data?.start.title}
          description={data?.start.description}
          buttonTitle={data?.start.button}
          setNextScreenNumber={handleStart}
        />
      );
    }
  }
};

export default WordGame;
