import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import ProhibitedChapterScreen from '../common/prohibited-chapter-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FourOptionsChallenge from '../common/challenge-component/four-options-challenge';
import AuthorityScreen from '../common/authority-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import SelectSeasonChallenge from '../common/challenge-component/select-season-challenge';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';
import ResidentsWhichCountryChallenge from '../common/challenge-component/residents-which-country-challenge';

const menu = [
  {
    id: 'challenge',
    screenNumber: 8,
    name: 'Челендж березня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 6,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  /* {
    id: 'authority',
    screenNumber: 2,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  }, */
  {
    id: 'difference',
    screenNumber: 15,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const selectedPack = {
  title: 'L&M Loft Blue',
  pack: getImageFromS3('images/packs/lm/loft-blue.png'),
  tobaccoFirst: 'Вміст смол, мг - 6',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3(
    'images/lm-double-splash/menu-background.png'
  )}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/'
};

const LM6 = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('L&M Loft Blue');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(12)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <FourOptionsChallenge
            setScreenNumber={setScreenNumber}
            type="default"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
        );
      }
      case 6: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image={getImageFromS3(
              'images/lm-double-splash/challenge/september-challenge/finally-icon.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
        );
      }
      case 8: {
        return (
          <ResidentsWhichCountryChallenge
            setScreenNumber={setScreenNumber}
            challengeId="residents-which-country"
            afterResultButtonScreen={() => setScreenNumber(10)}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
          // <WelcomeChallengeScreen
          //   setScreenNumber={setScreenNumber}
          //   backgroundImage={`url("${getImageFromS3(
          //     'images/lm-double-splash/background-new.png'
          //   )}")`}
          //   title={
          //     <>
          //       Гармонія в <br></br>самопізнанні
          //     </>
          //   }
          //   description={
          //     <>
          //       Ми підготували невеличкий<br></br>тест, який покаже твій тип
          //       <br></br>особистості.
          //     </>
          //   }
          //   buttonTitle="Пройти"
          //   setNextScreenNumber={() => setScreenNumber(14)}
          // />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <SelectSeasonChallenge
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
            setScreenNumber={setScreenNumber}
            type="harmony_in_self-knowledge-lm"
            style="soho"
            setNextScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 15: {
        return (
          <AuthorityScreen
            partners={partners}
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-6-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LM6;
