import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import HomeScreen from '../components/25ua-kv4/home-screen';

const KV3UA25 = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('25UA-KV4');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case 0: {
        return <HomeScreen />;
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="kv-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default KV3UA25;
