import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'taste-experimenter',
    title: 'Смаковий експериментатор',
    description:
      'Кожна кухня багата на неповторні смаки\n' +
      'Проходь тест та дізнайся, наскільки ти готовий до кулінарних експериментів та яку страву тобі варто спробувати.',
    backgroundImage: getImageFromS3(
      '/images/taste-experimenter-challenge/bg.png'
    ),
    welcomeBackgroundIcon: getImageFromS3(
      '/images/taste-experimenter-challenge/welcome-background-icon.png'
    ),
    startButtonTitle: 'Дивитися',
    questions: [
      {
        id: 1,
        title: 'Чи куштував ти колись зоофобусів, коників чи інших комах?',
        answerList: [
          { id: 1.1, title: 'Так' },
          { id: 1.2, title: 'Ні, але готовий спробувати' },
          { id: 1.3, title: 'Ні й не планую' }
        ]
      },
      {
        id: 2,
        title: 'Як ставишся до поєднання солодкого й солоного в одній страві?',
        answerList: [
          { id: 2.1, title: 'Люблю експерименти' },
          { id: 2.2, title: 'Ще не пробував, але зацікавлений' },
          { id: 2.3, title: 'Я прихильник класичних смаків' }
        ]
      },
      {
        id: 3,
        title:
          'Чи пробував ти десерти з гострим смаком, наприклад, шоколад із перцем?',
        answerList: [
          { id: 3.1, title: 'Мій улюблений смак' },
          { id: 3.2, title: 'Хочу спробувати' },
          { id: 3.3, title: 'Це не для мене' }
        ]
      },
      {
        id: 4,
        title:
          'Куштував коли-небудь страви з незвичних рослин, як-от кактус чи бамбук?',
        answerList: [
          { id: 4.1, title: 'Так' },
          { id: 4.2, title: 'Готовий до експериментів' },
          { id: 4.3, title: 'Не цікаво' }
        ]
      },
      {
        id: 5,
        title:
          'Як ставишся до \n' + 'сирої риби чи м’яса \n' + '(суші, тартар)?',
        answerList: [
          { id: 5.1, title: 'Обожнюю' },
          { id: 5.2, title: 'Не куштував, але спробую' },
          { id: 5.3, title: 'Не сприймаю' }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title: 'Експериментатор',
        description:
          'Ти — справжній кулінарний експериментатор! Радимо спробувати гункани з морськими їжаками. М’який смак цього делікатесу має тобі сподобатись.',
        image: getImageFromS3(
          '/images/taste-experimenter-challenge/result1.png'
        )
      },
      {
        id: 2,
        title: 'Відкритий до нового',
        description:
          'Ти відкритий до нового, але ще на шляху до сміливих експериментів. Почни з кімчі. Ця гостра корейська страва розпалить у тобі жагу до нового.',
        image: getImageFromS3(
          '/images/taste-experimenter-challenge/result2.png'
        )
      },
      {
        id: 3,
        title: 'Консерватор',
        description:
          'Ти відкритий до нових смаків, але надаєш перевагу зрозумілим стравам.  Пропонуємо тобі спробувати, наприклад, китайські дімсами. ',
        image: getImageFromS3(
          '/images/taste-experimenter-challenge/result3.png'
        )
      }
    ],
    afterResultContent: {
      title: 'Надихнувся куштувати нові страви?',
      description: 'Світ гастрономії безмежний, тож не бійся експериментувати!',
      image: getImageFromS3(
        '/images/taste-experimenter-challenge/after-result-image.png'
      ),
      nextButtonText: 'Далі'
    }
  }
];
