import { useMemo, useRef } from 'react';

const TELEGRAM_LINKS = {
  '23UA-MLB-T': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MLB-T',
  '23UA-MLB-F-T': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MLB-F-T',
  '23UA-LM-4': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-LM-4',
  '23UA-LM-6': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-LM-6',
  '23UA-BND-P-6': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-BND-P-6',
  '23UA-BND-P-4': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-BND-P-4',
  '23UA-MPM-6': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MPM-6',
  '23UA-MPM-4': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MPM-4',
  '23UA-MPM-N-M': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MPM-N-M',
  '23UA-MPM-N-M-S': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-MPM-N-M-S',
  '23UA-BND-P-M': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-BND-P-M',
  '23UA-LM-L-M': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-LM-L-M',
  '23UA-LM-D-S': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-LM-D-S',
  '23UA-Ches-6': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-Ches-6',
  '23UA-Ches-4': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-Ches-4',
  '23UA-PRL-S-6': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-S-6',
  '23UA-PRL-S-4': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-S-4',
  '23UA-PRL-V': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-V',
  '23UA-PRL-S-6-Test': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-S-6',
  '23UA-PRL-S-4-Test': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-S-4',
  '23UA-PRL-V-Test': 'https://t.me/pmu_support_bot?start=ScnPck-23UA-PRL-V',
  '24UA-MPM-PR': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-MPM-PR',
  '24UA-LM-L-Pr': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-L-Pr',
  '24UA-LM-L-green':
    'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-L-green',
  '24UA-Ches-Re': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Ches-Re',
  '24UA-KV': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-KV',
  '24UA-BND-B': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-BND-B',
  '24UA-BND-R': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-BND-R',
  '24UA-LM-B': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-B',
  '24UA-LM-R': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-R',
  '24UA-MLB-G': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-MLB-G',
  '24UA-MLB-R': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-MLB-R',
  '24UA-MLB-S': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-MLB-S',
  '24UA-Che-O': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Che-O',
  '24UA-Che-B': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Che-B',
  '24UA-KV2': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-KV2',
  '24UA-KV4': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-KV4',
  '24UA-MLB-T-LE': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-MLB-T-LE',
  '24UA-MLB-F-T-LE':
    'https://t.me/pmu_support_bot?start=ScnPck-24UA-MLB-F-T-LE',
  '24UA-Ches-SP': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Ches-SP',
  '24UA-Ches-SR': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Ches-SR',
  '24UA-LM-SP': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-SP',
  '24UA-LM-SR': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-SR',
  '24UA-LM-Spl': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-LM-Spl',
  '24UA-Ches-R': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-Ches-R',
  '24UA-PRL-N-LPE': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-N-LPE',
  '24UA-PRL-A-LPE': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-A-LPE',
  '24UA-PRL-S-LPE': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-S-LPE',
  '24UA-PRL-P-LPE': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-P-LPE',
  '24UA-PRL-N': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-N',
  '24UA-PRL-A': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-A',
  '24UA-PRL-S': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-S',
  '24UA-PRL-P': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-P',
  '24UA-PRL-A-SS': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-A-SS',
  '24UA-PRL-S-SS': 'https://t.me/pmu_support_bot?start=ScnPck-24UA-PRL-S-SS',
  '25UA-Ches-Prpl': 'https://t.me/pmu_support_bot?start=ScnPck-25UA-Ches-Prpl',
  '25UA-MLB-Ttn': 'https://t.me/pmu_support_bot?start=ScnPck-25UA-MLB-Ttn',
  '25UA-PRL-Sh-S-SSL':
    'https://t.me/pmu_support_bot?start=ScnPck-25UA-PRL-Sh-S-SSL',
  '25UA-PRL-Sh-A-SSL':
    'https://t.me/pmu_support_bot?start=ScnPck-25UA-PRL-Sh-A-SSL'
};

const DEFAULT_LINK = 'https://t.me/pmu_support_bot';

export default function useTelegramLink(projectKey) {
  const projectPathRef = useRef(sessionStorage.getItem('isQrCode'));
  projectPathRef.current = sessionStorage.getItem('isQrCode');

  const telegramLink = useMemo(() => {
    if (!projectPathRef.current && !projectKey) return;
    if (projectKey) return TELEGRAM_LINKS[projectKey] ?? DEFAULT_LINK;

    const _link = TELEGRAM_LINKS[projectPathRef.current];
    return _link ?? DEFAULT_LINK;
  }, []);

  return { telegramLink };
}
