import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    question:
      'У Саудівській Аравії продають парфуми, що тримаються на шкірі цілий місяць.',
    answerTitle: 'Брехня.',
    answer:
      'Жоден аромат не може триматися так довго на шкірі, якщо його не поновлювати.',
    image: getImageFromS3('images/trueOrFalse-challenge/1.png'),
    isCorrect: false
  },
  {
    id: 2,
    question: 'Найдорожчий у світі годинник створений з метеориту.',
    answerTitle: 'Правда.',
    answer:
      'У світі дійсно є годинник, що містить фрагменти метеориту. Такий аксесуар коштує близько $4,6 мільйона.',
    image: getImageFromS3('images/trueOrFalse-challenge/2.png'),
    isCorrect: true
  },
  {
    id: 3,
    question:
      'Краватку-метелик створили, щоб закривати комір сорочки, оскільки взимку аристократи мерзли.',
    answerTitle: 'Брехня.',
    answer:
      'Краватка-метелик не захищає від холоду, її створили як модну альтернативу звичайній краватці.',
    image: getImageFromS3('images/trueOrFalse-challenge/3.png'),
    isCorrect: false
  },
  {
    id: 4,
    question: 'Найдорожче вино у світі коштує понад $500,000 за пляшку.',
    answerTitle: 'Правда.',
    answer:
      'У 2018 році на аукціоні в Нью-Йорку пляшку французького вина 1945 року було продано\n' +
      'за $558,000.',
    image: getImageFromS3('images/trueOrFalse-challenge/4.png'),
    isCorrect: true
  },
  {
    id: 5,
    question:
      'Титан використовується у конструкції одного з найлюксовіших автомобілів у світі.',
    answerTitle: 'Правда.',
    answer:
      'Нещодавно на виставці у Дубаї було представлено ексклюзивне авто.\n' +
      'Один із головних елементів його конструкції – це титан, що надає автомобілю не лише унікальний вигляд, але й високу міцність та легкість.',
    image: getImageFromS3('images/trueOrFalse-challenge/5.png'),
    isCorrect: true
  }
];

const LieBackgroundIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="213"
      viewBox="0 0 187 213"
      fill="none"
      onClick={onClick}
    >
      <g filter="url(#filter0_f_299_2787)">
        <path
          d="M137 101C137 94.9249 132.075 90 126 90H36.875C31.1391 90 25.9053 86.7289 23.3919 81.573L8 50H-12V106.5V163H8L23.3919 131.427C25.9053 126.271 31.1391 123 36.875 123H126C132.075 123 137 118.075 137 112V101Z"
          fill="url(#paint0_linear_299_2787)"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_299_2787"
          x="-62"
          y="0"
          width="249"
          height="213"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="25"
            result="effect1_foregroundBlur_299_2787"
          />
        </filter>
        <linearGradient
          id="paint0_linear_299_2787"
          x1="11"
          y1="107"
          x2="114"
          y2="107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF152B" />
          <stop offset="1" stopColor="#C20D1E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const TruthBackgroundIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="189"
      height="213"
      viewBox="0 0 189 213"
      fill="none"
      onClick={onClick}
    >
      <g filter="url(#filter0_f_299_2788)">
        <path
          d="M50 101C50 94.9249 54.9249 90 61 90H150.125C155.861 90 161.095 86.7289 163.608 81.573L179 50H199V106.5V163H179L163.608 131.427C161.095 126.271 155.861 123 150.125 123H61C54.9249 123 50 118.075 50 112V101Z"
          fill="url(#paint0_linear_299_2788)"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_299_2788"
          x="0"
          y="0"
          width="249"
          height="213"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="25"
            result="effect1_foregroundBlur_299_2788"
          />
        </filter>
        <linearGradient
          id="paint0_linear_299_2788"
          x1="176"
          y1="107"
          x2="73"
          y2="107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C54B" />
          <stop offset="1" stopColor="#00C54B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const TrueOrFalseChallenge = ({
  setScreenNumber,
  backgroundImage,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);

  let touchStartX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const deltaX = touchEndX - touchStartX;

    if (deltaX < -90) {
      setShowAnswer(true);
    }

    if (deltaX > 90) {
      setShowAnswer(true);
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Правда чи брехня</p>
          <p className={styles.description}>
            У тебе витончене відчуття стилю
            <br />
            та бездоганний смак.
            <br />
            Тож ти знаєш багато
            <br />
            ексклюзивних речей.
            <br />
            Перевір себе — визнач, які факти
            <br />
            правдиві, а які ні.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Поїхали!</button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <div className={styles.challengeContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          {!isWelcomeScreen && !isResultScreen && (
            <div className={styles.questionContainer}>
              <p className={styles.title}>{selectQuestion?.question}</p>
              <div
                className={styles.imageWrapper}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                <img
                  src={selectQuestion?.image}
                  alt={selectQuestion?.image}
                  className={styles.image}
                />
                {showAnswer && (
                  <div
                    className={styles.answerContainer}
                    style={{
                      backgroundColor: selectQuestion?.isCorrect
                        ? 'rgba(1, 33, 7, 0.80)'
                        : 'rgba(33, 1, 1, 0.80)'
                    }}
                  >
                    <p>{selectQuestion?.answerTitle}</p>
                    <br />
                    <p>{selectQuestion?.answer}</p>
                  </div>
                )}
              </div>
              {!showAnswer && (
                <div className={styles.buttonContainer}>
                  <div className={styles.iconsContainer}>
                    <LieBackgroundIcon onClick={() => setShowAnswer(true)} />
                    <TruthBackgroundIcon onClick={() => setShowAnswer(true)} />
                  </div>
                  <div className={styles.textContainer}>
                    <p onClick={() => setShowAnswer(true)}>Брехня</p>
                    <p onClick={() => setShowAnswer(true)}>Правда</p>
                  </div>
                </div>
              )}
              {showAnswer && (
                <div className={styles.answeredButtonContainer}>
                  <div
                    className={styles.buttonItem}
                    style={{
                      visibility: challengeStep === 0 ? 'hidden' : 'visible'
                    }}
                    onClick={() => {
                      setChallengeStep(challengeStep - 1);
                      setSelectQuestion(challengeList[challengeStep - 1]);
                      touchStartX = 0;
                      setShowAnswer(false);
                    }}
                  >
                    <img
                      src="/images/luxury-expert-challenge/arrow.svg"
                      alt="arrow"
                    />
                    <span>Назад</span>
                  </div>
                  <div
                    className={styles.buttonItem}
                    onClick={() => {
                      setChallengeStep(challengeStep + 1);
                      setSelectQuestion(challengeList[challengeStep + 1]);
                      setShowAnswer(false);
                      touchStartX = 0;

                      if (challengeStep + 1 >= challengeList?.length)
                        setIsResultScreen(true);
                    }}
                  >
                    <span>Далі</span>
                    <img
                      src="/images/luxury-expert-challenge/arrow.svg"
                      alt="arrow"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isResultScreen && !isWelcomeScreen && (
        <div className={styles.resultContainer}>
          <p className={styles.title}>Ну як тобі гра?</p>
          <p className={styles.description}>
            Сподіваємось, що було пізнавально. Тепер ти знаєш ще більше цікавих
            фактів!
          </p>
          <button onClick={afterResultButtonScreen}>Далі</button>
        </div>
      )}
    </div>
  );
};

export default TrueOrFalseChallenge;
