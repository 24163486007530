import { getImageFromS3 } from '../../../imageService';

export const challengeComponent = [
  {
    id: 'rhythm-of-your-evening',
    title: 'Ритм твого вечора',
    description: 'Яким буде твій ідеальний вечір сьогодні?',
    backgroundImage: getImageFromS3(
      '/images/rhythm-of-your-evening-challenge/bg.png'
    ),
    subDescription: 'Відповідай на питання та отримуй ексклюзивний плейлист.',
    startButtonTitle: 'Поїхали!',
    questions: [
      {
        id: 1,
        title: 'Який вайб твого\n вечора?',
        answerList: [
          {
            id: 1.1,
            title: 'А) Енергійний, але домашній',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/1.1.png'
            )
          },
          {
            id: 1.2,
            title: 'Б) Релаксуючий',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/1.2.png'
            )
          },
          {
            id: 1.3,
            title: 'В) Драйвовий',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/1.3.png'
            )
          }
        ]
      },
      {
        id: 2,
        title: 'Який інструмент вважаєш\n найбільш важливим?',
        answerList: [
          {
            id: 2.1,
            title: 'А) Гітара',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/2.1.png'
            )
          },
          {
            id: 2.2,
            title: 'Б) Саксофон',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/2.2.png'
            )
          },
          {
            id: 2.3,
            title: 'В) Барабани',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/2.3.png'
            )
          }
        ]
      },
      {
        id: 3,
        title: 'Як ти почуваєшся\n зараз',
        answerList: [
          {
            id: 3.1,
            title: 'А) Трішки втомлено, але хочеться руху',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/3.1.png'
            )
          },
          {
            id: 3.2,
            title: 'Б) Розслаблено і задумливо',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/3.2.png'
            )
          },
          {
            id: 3.3,
            title: 'В) Заряджено енергією для пригод',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/3.3.png'
            )
          }
        ]
      },
      {
        id: 4,
        title: 'Яка погода сьогодні найбільше\n відповідає твоєму настрою?',
        answerList: [
          {
            id: 4.1,
            title: 'А) Теплий дощ за вікном',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/4.1.png'
            )
          },
          {
            id: 4.2,
            title: 'Б) Спокійний захід сонця',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/4.2.png'
            )
          },
          {
            id: 4.3,
            title: 'В) Яскрава ніч із зірками',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/4.3.png'
            )
          }
        ]
      },
      {
        id: 5,
        title: 'Який найкращий напій\n вечора?',
        answerList: [
          {
            id: 5.1,
            title: 'А) Гарячий шоколад або чай',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/5.1.png'
            )
          },
          {
            id: 5.2,
            title: 'Б) Фреш чи трав’яний напій',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/5.2.png'
            )
          },
          {
            id: 5.3,
            title: 'В) Освіжаючий коктейль',
            image: getImageFromS3(
              '/images/rhythm-of-your-evening-challenge/5.3.png'
            )
          }
        ]
      }
    ],
    resultList: [
      {
        id: 1,
        title:
          'Ти хочеш трохи руху, але в межах дому. Тримай музику, яка допоможе створити атмосферу енергійного затишку.',
        image: getImageFromS3(
          '/images/rhythm-of-your-evening-challenge/result1.png'
        ),
        playList:
          'https://youtube.com/playlist?list=PLuFSqtT3HMeApR_sPpm7zzH-1Ba1vKX5D&si=08ToK24U5YLvPpuF'
      },
      {
        id: 2,
        title:
          'Тобі потрібна музика для розслаблення та занурення у власні думки. Лови плейлист, що ідеально підійде для твого вечора.',
        image: getImageFromS3(
          '/images/rhythm-of-your-evening-challenge/result2.png'
        ),
        playList:
          'https://www.youtube.com/playlist?list=PLuFSqtT3HMeDGoyg1kEqwDRsR9EGqDPQB&si=GVTJK10IPkRMnggV'
      },
      {
        id: 3,
        title:
          'Твій вечір сповнений драйву і готовий до вибуху емоцій. Ось добірка треків, які зарядять енергією.',
        image: getImageFromS3(
          '/images/rhythm-of-your-evening-challenge/result3.png'
        ),
        playList:
          'https://youtube.com/playlist?list=PLuFSqtT3HMeDmnHNpTI65VhAxAXqCb1gR&si=qxUeKQWXQdcfrsmh'
      }
    ]
  }
];
