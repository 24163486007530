import React, { useState, useEffect } from 'react';
import styles from '../components/pmn/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import InfoScreen from '../components/lm-d-s/info-screen';
import AuthorityScreen from '../common/authority-screen';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import DailyTasksChallenge from '../common/challenge-component/daily-tasks-challenge';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import ProhibitedChapterScreen from '../components/lm-d-s/prohibited-chapter-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import WelcomeScreen from '../components/bond/welcome-screen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const PROJECT_NAME = 'Bond street premium mix';

const dailyTasksChallengeList = {
  1: (
    <>Життя - це не те, що відбувається з тобою, а те, як ти реагуєш на це 😎</>
  ),
  2: (
    <>
      Ти маєш вибір: бути оптимістом чи песимістом. Обирай оптимізм — він веде
      до світлого майбутнього 💡
    </>
  ),
  3: <>Успіх – це сума маленьких зусиль, повторюваних щодня 🎯</>,
  4: <>Не бійся змін — вони можуть виявитися кращим, ніж ти очікував 🤫</>,
  5: <>Сьогодні — єдиний день, який маємо. Використовуй його максимально ⏳</>,
  6: (
    <>
      Ти не можеш вплинути на те, що сталося вчора, але ти можеш визначити своє
      завтра 🌠
    </>
  ),
  7: <>Віра в себе — це перший секрет успіху ⚡</>,
  8: <>Мрій, якщо хочеш, але не забувай прокидатися і діяти 🚀</>,
  9: <>Не обговорюй свої плани — реалізовуй їх 🚀</>,
  10: <>Щоб досягти успіху, ти маєш пройти туди, куди інші бояться 🔥</>,
  11: <>Твій час обмежений, не витрачай його, живучи чужим життям ⏳</>,
  12: (
    <>
      Зроби своє сьогодення таким чудовим, щоб його було приємно згадувати
      завтра 🎞
    </>
  ),
  13: (
    <>Завжди можна знайти щось позитивне, навіть у найскладніших ситуаціях 🧐</>
  ),
  14: <>Ти маєш в собі неймовірну силу — знайди її та використай 😎</>,
  15: <>Найбільший успіх — це перемога над собою 🏆</>,
  16: <>Не бійся ризикувати — це може відкрити нові можливості 📌</>,
  17: <>Будь завзятим у тому, що ти робиш, і вір у свою силу 😎</>,
  18: <>Життя — це не те, що тобі випадає, а те, що ти робиш 🎨</>,
  19: <>Навчайся на помилках і рухайся вперед ⚡</>,
  20: (
    <>Не чекай на ідеальний момент — він ніколи не прийде. Починай зараз ✨</>
  ),
  21: (
    <>
      Ти не можеш змінити напрямок вітру, але завжди можеш налаштувати вітрила
      🤫
    </>
  ),
  22: (
    <>
      Справжня сила полягає в тому, щоб не здаватися, коли здається, що вже
      немає сил ⚡
    </>
  ),
  23: <>Підкреслюй свою унікальність — це те, що робить тебе особливим 🎨</>,
  24: <>Прагнення до успіху важливіше за сам успіх 🧐</>,
  25: <>Ти можеш зробити більше, ніж ти думаєш 😉</>,
  26: (
    <>Будь завзятий у своїх мріях, бо саме ти робиш своє життя неймовірним 😉</>
  ),
  27: <>Наважся мріяти масштабно і починай з того, що маєш 😎</>,
  28: <>Справжня свобода — це можливість бути самим собою 😉</>,
  29: <>Переможці не роблять виправдань, вони створюють можливості 😎</>,
  30: <>Не дивися назад, ти йдеш у майбутнє 🌠</>,
  31: <>Зміни світ, починаючи з себе ✨</>,
  32: (
    <>Ти визначаєш свій успіх — не дозволяй іншим визначати його за тебе 🙂</>
  ),
  33: (
    <>Усе починається з першого кроку. Якщо не рухаєшся, ніколи не дійдеш 🧐</>
  ),
  34: (
    <>
      Лише той, хто ризикує заради своїх мрій, може досягти великих успіхів 🏆
    </>
  ),
  35: (
    <>
      Твої можливості обмежуються лише тим, що ти готовий уявити. Мрій широко!
      ✨
    </>
  )
};

const menuList = [
  {
    id: 'challenge',
    screenNumber: 6,
    name: 'Челендж березня',
    icon: 'images/common/menu-icon/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 13,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 2,
    name: 'Характеристики сигарет',
    icon: 'images/common/menu-icon/characteristic-menu-icon.svg'
  },
  {
    id: 'about',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/common/menu-icon/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 11,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review_main',
    screenNumber: 5,
    name: 'Залишити відгук про продукт',
    icon: 'images/common/menu-icon/review-menu-icon.svg'
  }
];

const bondStreetPremiumMix = {
  title: 'Bond Street Premium Mix',
  pack: getImageFromS3('images/packs/bond/premium-mix.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.4',
  filterIcon: getImageFromS3('images/bond/filter.png'),
  background: `url("${getImageFromS3('images/bond/background.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          <b>Класичний ацетатний фільтр</b> з високоякісної целюлози, який
          затримує тверді частинки сигаретного диму. Містить капсулу зі смаком
          лісових ягід.
        </span>
      ),
      bordered: false
    }
  ]
};

const lmLoftPurple = {
  title: 'L&M Loft Purple',
  pack: getImageFromS3('images/packs/lm/loft-purple.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  isDarkButton: true,
  isNewMixture: true,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const novelPurple = {
  title: 'Philip Morris Novel Purple',
  pack: getImageFromS3('images/packs/philip-morris/novel-purple.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  isDarkButton: true,
  isNewMixture: true,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const lmLoftGreen = {
  title: 'L&M Loft Green',
  pack: getImageFromS3('images/packs/lm/loft-green.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  isDarkButton: true,
  isNewMixture: true,
  newMixtureText: 'Особлива тютюнова суміш',
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const chesterfieldRetuned = {
  title: 'Chesterfield Retuned',
  pack: getImageFromS3('images/packs/chesterfield/retuned.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  isDarkButton: true,
  isNewMixture: true,
  newMixtureText: 'Особлива тютюнова суміш',
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

export default function BNDPM() {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init(PROJECT_NAME);
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(12);
            }}
            handleBackButton={() => setScreenNumber(2)}
          />
        );
      }
      case 0: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            handleBackButton={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/violet-background.png'
            )}")`}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menuList}
            backgroundImage={`url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <CharacteristicScreen
            brandId="bond"
            isNewMixture={bondStreetPremiumMix?.isNewMixture}
            title={bondStreetPremiumMix?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={
              bondStreetPremiumMix?.filterCharacteristicList
            }
            infoList={bondStreetPremiumMix?.characteristicInfoList}
            tobaccoFirst={bondStreetPremiumMix?.tobaccoFirst}
            tobaccoSecond={bondStreetPremiumMix?.tobaccoSecond}
            packImage={bondStreetPremiumMix?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={bondStreetPremiumMix?.background}
            filterIcon={bondStreetPremiumMix?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 4: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 5: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 6: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title={
              <>
                Натхнення
                <br />
                на день
              </>
            }
            description={`Трохи не вистачає мотивації? Ми підготували генератор натхнення, який точно налаштує тебе на правильний настрій.`}
            buttonTitle="Почати"
            backgroundImage={`url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(7)}
          />
        );
      }
      case 7: {
        return (
          <DailyTasksChallenge
            title={
              <>
                Твоя порція натхнення
                <br />
                на сьогодні:
              </>
            }
            acceptChallengeCopy="Приймаю"
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`}
            dailyTasksList={dailyTasksChallengeList}
            dailyTasksListCount={Object.keys(dailyTasksChallengeList).length}
          />
        );
      }
      case 8: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(9)}
            title="Відчуваєш позитивний заряд?"
            description={
              <>
                Заходь до нас завтра та отримай <br />
                нову порцію натхнення.
              </>
            }
            image={getImageFromS3('images/bond/man-c.png')}
            backgroundImage={`url("${getImageFromS3(
              'images/bond/background-new.png'
            )}")`}
          />
        );
      }
      case 9: {
        return (
          <FinalScreen
            setScreenNumber={cb}
            setScreenNumberToReview={() => setScreenNumber(5)}
            withReviewButton
            isUpdatedApp
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 11: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 12: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(9)}
          />
        );
      }
      case 13: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 14: {
        return (
          <CharacteristicScreen
            brandId="philip-morris"
            isNewMixture={novelPurple?.isNewMixture}
            title={novelPurple?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={novelPurple?.filterCharacteristicList}
            infoList={novelPurple?.characteristicInfoList}
            tobaccoFirst={novelPurple?.tobaccoFirst}
            tobaccoSecond={novelPurple?.tobaccoSecond}
            packImage={novelPurple?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={novelPurple?.background}
            filterIcon={novelPurple?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 15: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={lmLoftPurple?.isNewMixture}
            title={lmLoftPurple?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={lmLoftPurple?.filterCharacteristicList}
            infoList={lmLoftPurple?.characteristicInfoList}
            tobaccoFirst={lmLoftPurple?.tobaccoFirst}
            tobaccoSecond={lmLoftPurple?.tobaccoSecond}
            packImage={lmLoftPurple?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={lmLoftPurple?.background}
            filterIcon={lmLoftPurple?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 16: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={lmLoftGreen?.isNewMixture}
            title={lmLoftGreen?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={lmLoftGreen?.filterCharacteristicList}
            infoList={lmLoftGreen?.characteristicInfoList}
            tobaccoFirst={lmLoftGreen?.tobaccoFirst}
            tobaccoSecond={lmLoftGreen?.tobaccoSecond}
            packImage={lmLoftGreen?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={lmLoftGreen?.background}
            filterIcon={lmLoftGreen?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 17: {
        return (
          <CharacteristicScreen
            brandId="chesterfield"
            isNewMixture={chesterfieldRetuned?.isNewMixture}
            title={chesterfieldRetuned?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={
              chesterfieldRetuned?.filterCharacteristicList
            }
            infoList={chesterfieldRetuned?.characteristicInfoList}
            tobaccoFirst={chesterfieldRetuned?.tobaccoFirst}
            tobaccoSecond={chesterfieldRetuned?.tobaccoSecond}
            packImage={chesterfieldRetuned?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(9)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={chesterfieldRetuned?.background}
            filterIcon={chesterfieldRetuned?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
}
