import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';
import classNames from 'classnames';

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundImage }}>
        <div className={styles.headContainer}>
          <p>Від бренду</p>
          <img
            src={getImageFromS3('images/sms-che/headTitle.png')}
            alt="head-title"
          />
        </div>
        <img
          className={styles.packImage}
          src={getImageFromS3('images/sms-che/packContainer.png')}
          alt="pack-container"
        />
        <div className={styles.characteristicContainer}>
          <img
            src={getImageFromS3('images/sms-che/back-animation.gif')}
            className={styles.background}
            alt="background"
          />
          <img
            src={getImageFromS3('images/sms-che/tobaccoIcon.png')}
            alt="tobacco-icon"
          />
          <p>
            <b>Оновлена</b> тютюнова суміш
          </p>
        </div>
        <div
          className={classNames(
            styles.characteristicContainer,
            styles.secondCharacteristicContainer
          )}
        >
          <img
            src={getImageFromS3('images/sms-che/back-animation-2.gif')}
            className={styles.background}
            alt="background"
          />
          <p>
            <b>Фільтр</b> з повітряною камерою
          </p>
          <img
            src={getImageFromS3('images/sms-che/filterIcon.png')}
            alt="filter-icon"
          />
        </div>
        <div
          className={classNames(
            styles.characteristicContainer,
            styles.thirdCharacteristicContainer
          )}
        >
          <img
            src={getImageFromS3('images/sms-che/back-animation.gif')}
            className={styles.background}
            alt="background"
          />
          <img
            src={getImageFromS3('images/sms-che/packFormat.png')}
            alt="pack-icon"
          />
          <p>
            <b>Прогресивний</b> компактний формат
          </p>
        </div>
        <div className={styles.buyContainer}>
          <img
            src={getImageFromS3('images/sms-che/buy-background.png')}
            className={styles.background}
            alt="buy-background"
          />
          <p className={styles.title}>Де купити?</p>
          <p className={styles.description}>
            Актуальний асортимент можна знайти у роздрібних точках продажу в
            твоєму місті або на офіційних онлайн сервісах, зокрема:
          </p>
          <div className={styles.buttonContainer}>
            <div>
              <a href="https://cig-poshta.in.ua/chesterfield-purple/?utm_source=smsChePrpl">
                Cig-poshta
              </a>
            </div>
            <div>
              <a href="https://rozetka.com.ua/ua/chesterfield-4823003216195/p478062109/">
                Rozetka
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав(-ла) лист, оскільки залишав(-ла) запит на отримання
        інформації про продукцію компаній Philip Morris в Україні. <br />
        <a href="/unsubscribe?returnLink=25-sms-che">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
